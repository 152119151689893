import React, { useEffect, useCallback, useMemo } from 'react';
import CryptoJS from 'crypto-js';
import { useAuth0 } from '@auth0/auth0-react';
import { useSearchParams } from 'react-router-dom';

const Login: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  const [params] = useSearchParams();

  // Memoize the domain to avoid recomputation
  const domain = useMemo(() => process.env.REACT_APP_DOMAIN || '', []);

  // Function to encode a string in Base64 URL format
  const base64URLEncode = useCallback((str: CryptoJS.lib.WordArray) => {
    const base64 = CryptoJS.enc.Base64.stringify(str);
    return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/g, '');
  }, []);

  // Function to compute the SHA-256 hash of a given input
  const sha256 = useCallback((buffer: CryptoJS.lib.WordArray) => {
    const hash = CryptoJS.SHA256(buffer);
    return CryptoJS.enc.Base64.parse(hash.toString(CryptoJS.enc.Base64));
  }, []);

  // Memoize the code challenge generation to avoid recomputation
  const generateCodeChallenge = useMemo(() => {
    const verifier = CryptoJS.lib.WordArray.random(32);
    return base64URLEncode(sha256(verifier));
  }, [base64URLEncode, sha256]);

  // Function to initiate the login process
  const initiateLogin = useCallback(async () => {
    await loginWithRedirect({
      code_challenge_method: 'S256',
      code_challenge: generateCodeChallenge,
      redirectUri: `${domain}/${params.get('dealId') || ''}`,
    });
  }, [loginWithRedirect, generateCodeChallenge, domain, params]);

  // Effect to initiate the login process when the component mounts
  useEffect(() => {
    initiateLogin();
  }, [initiateLogin]);

  return (
    <div>
      <p>Redirecting to login...</p>
    </div>
  );
};

export default Login;
