import React, { useState, useEffect, useMemo } from 'react';
import { Container } from '@mantine/core';
import withHOCDisplayName from 'hocs/withHOCDisplayName';
import Footer from 'layout/components/Footer/Footer';
import TopNavigation from 'layout/components/TopNavigation/TopNavigation';
import { useAuth0Context } from 'components/Auth0/Auth0Context';
import { NotificationWrapper, WiringModal } from 'components/Common/WiredNotification';

/* eslint-disable react/display-name */
let showedNotification = false;

function withPublicLayout<T extends JSX.IntrinsicAttributes>(WrappedComponent: React.ComponentType<T>): React.FC<T> {
  return (props) => {
    const { commitments } = useAuth0Context();
    const [visible, setVisible] = useState(false);
    const [openWiringModal, setWiringModal] = useState<boolean>(false);
    const [selectedCommitment, setSelectedCommitment] = useState<any>(null);

    const handleClose = () => {
      setVisible(false);
    };

    const openModal = (commitmentData: any) => {
      setSelectedCommitment(commitmentData);
      setWiringModal(true);
    };

    const closeModal = () => {
      setWiringModal(false);
      setSelectedCommitment(null);
    };

    useEffect(() => {
      if (commitments && commitments.length && !showedNotification) {
        showedNotification = true;
        setVisible(true);
      }
    }, [commitments]);

    const wrappedComponentMemo = useMemo(() => <WrappedComponent {...(props as T)} />, [props]);

    return (
      <>
        <TopNavigation />
        <Container sx={{ padding: 0 }} my={52} size="lg">
          {visible && <NotificationWrapper commitments={commitments} openModal={openModal} onClose={handleClose} />}
          <WiringModal opened={openWiringModal} onClose={closeModal} commitment={selectedCommitment} />
          {wrappedComponentMemo}
        </Container>
        <Footer />
      </>
    );
  };
}

export default withHOCDisplayName(withPublicLayout);
