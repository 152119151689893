import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IconCheck } from '@tabler/icons';
import { toast } from 'react-toastify';
import { Button } from '@mantine/core';
import { Entity } from 'models/invest/entity.api.model';
import useToggle from 'hooks/useToggle';
import UboTable from './UboTable';

interface EntityCreatedProps {
  entity: Entity; // Array of UBO names or emails
  onClose: () => void; // Callback for the OK button
  onKycVerificationStart: () => void;
}

const EntityCreated: React.FC<EntityCreatedProps> = ({ entity, onClose, onKycVerificationStart }) => (
  <div className="entity-created-container">
    <div className="check-icon-container">
      <IconCheck className="check-icon" />
    </div>
    <p className="success-message">
      Entity has been successfully created! Please complete the <strong>KYC</strong> / <strong>KYB</strong> process to
      proceed with your investment.
    </p>

    {entity && entity.ubos && entity.ubos.length > 0 && (
      <>
        <p className="email-notification">
          We have sent an email to the listed UBOs for KYC. It may take a few moments to be delivered.
        </p>
        <UboTable ubos={entity.ubos} />
      </>
    )}

    <Button className="ok-button" onClick={onKycVerificationStart} variant="filled" color="green">
      Start verification
    </Button>
  </div>
);

export default EntityCreated;
