import React from 'react';
import { Stack, Title, Checkbox, Text, Anchor, createStyles } from '@mantine/core';
import { trackEventsOnClick } from 'analytics';

interface TermsAndPaymentProps {
  checkboxValue: string[];
  setCheckboxValue: (value: string[]) => void;
  termSheet: string;
  showError: boolean;
}

const useStyles = createStyles((theme) => ({
  checkboxError: {
    backgroundColor: '#FF8787',
    transition: 'background-color 0.3s ease',
  },
}));

const TermsAndPayment: React.FC<TermsAndPaymentProps> = ({ checkboxValue, setCheckboxValue, termSheet, showError }) => {
  const { classes } = useStyles();

  const isSelected = (value: string) => checkboxValue.includes(value);

  return (
    <Stack className="invest-termspayment" mt="md">
      <Title order={4} className="invest-termspayment--title">
        Terms & payment
      </Title>
      <Checkbox.Group value={checkboxValue} onChange={setCheckboxValue} orientation="vertical">
        <Checkbox
          radius="xs"
          value="subscription_agreement"
          classNames={{
            input: showError && !isSelected('subscription_agreement') ? classes.checkboxError : '',
          }}
          label={
            <>
              I agree to the terms and provisions of the{' '}
              <Anchor
                fw={600}
                href={termSheet}
                target="_blank"
                className="highlighted-anchor"
                data-event="subscription_doc"
                onClick={trackEventsOnClick}
              >
                Subscription Agreement
              </Anchor>
            </>
          }
        />
        <Checkbox
          radius="xs"
          value="privacy_policy_consent"
          classNames={{
            input: showError && !isSelected('privacy_policy_consent') ? classes.checkboxError : '',
          }}
          label={
            <>
              I agree to the{' '}
              <Anchor
                fw={600}
                href="https://www.tokenfolio.co/terms-of-service"
                target="_blank"
                className="highlighted-anchor"
                data-event="terms_of_service"
                onClick={trackEventsOnClick}
              >
                Terms of Service
              </Anchor>{' '}
              &{' '}
              <Anchor
                fw={600}
                href="https://www.tokenfolio.co/privacy-policy"
                target="_blank"
                className="highlighted-anchor"
                data-event="privacy_policy"
                onClick={trackEventsOnClick}
              >
                Privacy Policy
              </Anchor>
            </>
          }
        />
        <Checkbox
          radius="xs"
          value="risk_factors"
          classNames={{
            input: showError && !isSelected('risk_factors') ? classes.checkboxError : '',
          }}
          label={
            <>
              I understand that this investment involves substantial risks and acknowledge risks outlined in the{' '}
              <Anchor
                fw={600}
                href="https://www.tokenfolio.co/risks"
                target="_blank"
                className="highlighted-anchor"
                data-event="risk_factors"
                onClick={trackEventsOnClick}
              >
                Risk Factors
              </Anchor>
            </>
          }
        />
      </Checkbox.Group>
    </Stack>
  );
};

export default TermsAndPayment;
