import { ColDef } from 'ag-grid-community';
import StatusDropdown from 'features/Dashboard/components/StatusDropdown';
import SubscriptionLink from 'features/Dashboard/components/SubscriptionLink';
import WiredData from 'features/Dashboard/components/WiredData';
import ReturnCell from 'features/Dashboard/components/ReturnCell';
import ConfirmWired from 'features/Dashboard/components/ConfirmWired';
import { formatFloorNumber } from 'utils/currencySymbols';

export const columnDefs: ColDef[] = [
  {
    headerName: 'Entity Name',
    field: 'entity_name',
  },
  {
    headerName: 'Company',
    field: 'company',
  },
  {
    headerName: 'Status',
    field: 'status',
    cellRenderer: StatusDropdown,
  },
  {
    headerName: 'Date',
    field: 'timestamp',
    sortable: true,
    valueFormatter: (params) =>
      new Intl.DateTimeFormat('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      }).format(new Date(params.value)),
  },
  {
    headerName: 'Invested',
    field: 'total_subscription_amount',
    valueFormatter: (params) => `$${formatFloorNumber(params.value)}`,
  },
  {
    headerName: 'Current Value',
    field: 'current_value',
    valueFormatter: (params) => (params.value ? `$${formatFloorNumber(params.value)}` : 'N/A'),
  },
  {
    headerName: 'Return %',
    field: 'return',
    cellRenderer: ReturnCell,
  },
  {
    headerName: 'Documents',
    field: 'signature_id',
    cellRenderer: SubscriptionLink,
    minWidth: 100,
  },
  {
    headerName: '',
    field: '',
    cellRenderer: WiredData,
  },
  {
    headerName: '',
    field: '',
    cellRenderer: ConfirmWired,
  },
];
