// utils/apiUtils.ts
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import backendConfig from 'config/backend.config';
import axios from 'axios';

export const createBaseQuery = (token: string | null) =>
  fetchBaseQuery({
    baseUrl: `${backendConfig.baseUrl}v1`,
    prepareHeaders: (headers) => {
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
        // headers.set('Content-Type', 'application/json; charset=UTF-8');
      }
      return headers;
    },
  });

interface FetchDataOptions {
  url: string;
  method: 'GET' | 'POST' | 'PUT';
  token: string | null;
  data?: any; // Request body for POST or PUT methods
  responseType?: 'json' | 'blob';
}

export async function fetchData({ url, method, token, data, responseType = 'json' }: FetchDataOptions) {
  const headers: any = {
    Authorization: `Bearer ${token}`,
    // 'Content-Type': 'application/json',
  };

  if (method !== 'GET') {
    headers['Content-Type'] = 'application/json';
  }

  try {
    const response = await axios({
      method,
      url,
      headers,
      data, // Include the request body if present
      responseType, // Set the response type based on the parameter
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Axios error fetching data:', error.response?.data);
      throw error.response?.data || error.message; // Re-throw specific error data if available
    } else {
      console.error('Error fetching data:', error);
      throw error; // Re-throw the error for handling at a higher level
    }
  }
}
