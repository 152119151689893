import React from 'react';
import { createRoot } from 'react-dom/client';

import reportWebVitals from 'reportWebVitals';

import { Provider as ReduxProvider } from 'react-redux';
import store from 'store/store';
import auth0Config from 'config/auth0.config';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import App from 'App';
import { Auth0Provider as Auth0Wrapper } from 'components/Auth0/Auth0Context';

createRoot(document.getElementById('tf-root') as HTMLElement).render(
  <React.StrictMode>
    <Auth0Provider
      domain={auth0Config.domain}
      clientId={auth0Config.clientId}
      scope={auth0Config.scope}
      audience={auth0Config.audience}
      redirectUri={auth0Config.redirectUri}
    >
      <BrowserRouter>
        <ReduxProvider store={store}>
          <Auth0Wrapper>
            <App />
          </Auth0Wrapper>
        </ReduxProvider>
      </BrowserRouter>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
