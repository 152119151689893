import React from 'react';
import { Title, Text, Group } from '@mantine/core';
import { IconShare, IconWallet, IconInfinity, IconGift } from '@tabler/icons';

export const HowItWorksSection: React.FC = () => (
  <div style={{ marginTop: '1rem', textAlign: 'center' }}>
    <Title order={3} style={{ marginBottom: '2rem' }}>
      HOW IT WORKS
    </Title>
    {/* <Text size="lg" weight={500} style={{ marginBottom: '2rem' }}>
        Share private investing and <span style={{ color: '#007bff' }}>earn rewards!</span>
      </Text> */}
    <Group position="apart" grow className="how-it-works-box">
      <div>
        <IconShare size={40} color="#007bff" />
        <Title order={5} style={{ margin: '1rem 0' }}>
          Share Your Link
        </Title>
        <Text size="sm">Send your $1,000 gift link to friends and family.</Text>
      </div>
      <div>
        <IconWallet size={40} color="#28a745" />
        <Title order={5} style={{ margin: '1rem 0' }}>
          Your Friend Invests
        </Title>
        <Text size="sm">Your friends unlock $1,000 upon signup, towards their first investment.</Text>
      </div>
      <div>
        <IconGift size={40} color="#FFD700" />
        <Title order={5} style={{ margin: '1rem 0' }}>
          You Earn Rewards
        </Title>
        <Text size="sm">You get $1,000 in Tokenfolio Wallet for every friend’s first investment.</Text>
      </div>
      <div>
        <IconInfinity size={40} color="#ffc107" />
        <Title order={5} style={{ margin: '1rem 0' }}>
          Unlimited Referrals
        </Title>
        <Text size="sm">Invite as many friends as you want, no cap on TF wallet earnings!</Text>
      </div>
    </Group>
  </div>
);
