import React from 'react';

interface ReturnCellProps {
  value: number | null | undefined;
}

const ReturnCell: React.FC<ReturnCellProps> = ({ value }) => {
  if (value === null || value === undefined) {
    return <span style={{ color: '#6c757d' }}>N/A</span>;
  }
  const isProfit = value >= 0;
  const color = isProfit ? 'rgb(64, 192, 87)' : 'rgb(250, 82, 82)';
  const formattedValue = `${isProfit ? '+' : '-'}${value}%`;

  return <span style={{ color, fontWeight: 'bold' }}>{formattedValue}</span>;
};

export default ReturnCell;
