import React, { useState } from 'react';
import { Modal, TextInput, Button, Flex } from '@mantine/core';
import { useApplyGiftCodeMutation } from 'services/referral/referral.service';
import { ProductAnalytics, PaEvents } from 'analytics';
import { toast } from 'react-toastify';
// import { useApplyGiftCodeMutation } from '../services/wallet.service';

interface GiftCodeModalProps {
  opened: boolean;
  onClose: () => void;
}

const GiftCodeModal: React.FC<GiftCodeModalProps> = ({ opened, onClose }) => {
  const [giftCode, setGiftCode] = useState('');
  const [applyGiftCode, { isLoading }] = useApplyGiftCodeMutation();

  const handleSubmit = async () => {
    try {
      // Make the API call without using `.unwrap()`
      const result: any = await applyGiftCode({ code: giftCode });
      // Check if the result contains an error
      if (result.error) {
        // Handle the error case
        const { status, data } = result.error;

        if (status === 400 && typeof data === 'string') {
          // If the error data is a string (like HTML content), extract the message
          const parser = new DOMParser();
          const htmlDoc = parser.parseFromString(data, 'text/html');
          const messageElement = htmlDoc.querySelector('p');
          const message = messageElement ? messageElement.textContent : 'Bad Request';

          toast.error(message); // Display the error message from HTML or other content
        } else if (data?.message) {
          // If there's a JSON error response with a `message` field
          toast.error(data.message);
        } else {
          toast.error('Something went wrong');
        }
      } else {
        // Success case: If no error, show the success message
        ProductAnalytics.trackEvent(PaEvents.REFERRAL, {
          referral_success: true,
        });
        toast.success('Congrats! $1000 credited to your wallet.');
        onClose();
      }
    } catch (error: any) {
      // Catch other errors like network issues
      toast.error('Unable to connect. Please check your internet connection.');
    }
  };

  return (
    <Modal opened={opened} onClose={onClose} title="Enter Gift Code">
      <TextInput
        value={giftCode}
        onChange={(e) => setGiftCode(e.target.value)}
        placeholder="Enter your gift code"
        mb="md"
      />
      <Flex justify="flex-end" gap={20}>
        <Button onClick={onClose} variant="default">
          Cancel
        </Button>
        <Button onClick={handleSubmit} loading={isLoading} loaderPosition="right">
          Submit
        </Button>
      </Flex>
    </Modal>
  );
};

export default GiftCodeModal;
