import { PaEvents } from './PaEvents';
import ProductAnalytics from './ProductAnalytics';

const trackEventsOnClick = (
  event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  extraProperties?: Record<string, any>,
  eventName?: PaEvents
) => {
  const eventType = event.currentTarget.getAttribute('data-event');

  if (eventType) {
    ProductAnalytics.trackEvent(eventName || PaEvents.DEAL_INFO, {
      info_type: eventType,
      ...(extraProperties || {}),
    });
  }
};

export { PaEvents, ProductAnalytics, trackEventsOnClick };
