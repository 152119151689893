import React from 'react';
import { Stack, Title, Text, Card, Box } from '@mantine/core';
import './Wired.scss';

const WiredInstruction: React.FC<{ commitmentAmount?: string }> = ({ commitmentAmount }) => (
  <>
    <Box className="disclaimer-message">
      <>
        <b>Important:</b> Please ensure the account holder name matches the signatory name while wiring. Wires from
        different account names won’t be accepted and will be returned.
        <div className="highlight-message">
          <i>
            Please note that returns might cause delay and we won’t be responsible for any forex / trx charges lost if
            you wire from the wrong accounts
          </i>{' '}
        </div>
      </>
    </Box>
    <Card>
      <Stack>
        <Title order={4} weight={550}>
          Wiring instructions{commitmentAmount && `: please transfer $${commitmentAmount}`}
        </Title>
        <Text color="dimmed" mt={4} mb={-15}>
          Transfer funds before the deal closes to avoid cancellation
        </Text>

        <Text size="sm" mt={4}>
          - Please ensure all banking fees, charges or FX spreads be charged as <b>OUR</b> only.
        </Text>
        <Text size="sm" mt={-15}>
          - Please remit funds in USD only
        </Text>
        <Text size="sm" mt={-15}>
          - If you are asked for a purpose code, please use P0093
        </Text>

        <Box className="wired-bank-box">
          <Box className="wired-bank-info">
            <Text size="sm">Account A/c Number:</Text>
            <Text size="sm" weight={550}>
              409001935968
            </Text>
          </Box>
          <Box className="wired-bank-info">
            <Text size="sm">Account A/c Name:</Text>
            <Text size="sm" weight={550}>
              TOKENFOLIO (BVI) LIMITED
            </Text>
          </Box>

          <Box className="wired-bank-info">
            <Text size="sm">Beneficiary Bank Name:</Text>
            <Text size="sm" weight={550}>
              RBL Bank Ltd – IFSC Banking Unit, GIFT City, India
            </Text>
          </Box>
          <Box className="wired-bank-info">
            <Text size="sm">NOSTRO Bank SWIFT Code/BIC:</Text>
            <Text size="sm" weight={550}>
              RATNINB2
            </Text>
          </Box>
          <Box className="wired-bank-info">
            <Text size="sm">Intermediary / Correspondent/ Nostro Bank:</Text>
            <Text size="sm" weight={550}>
              WELLS FARGO BANK, NEW YORK
            </Text>
          </Box>
          <Box className="wired-bank-info">
            <Text size="sm">Correspondent bank SWIFT / Local Clearing IFSC Code:</Text>
            <Text size="sm" weight={550}>
              PNBPUS3NNYC
            </Text>
          </Box>
          <Box className="wired-bank-info">
            <Text size="sm">Correspondent Bank A/c No:</Text>
            <Text size="sm" weight={550}>
              2000294891609
            </Text>
          </Box>

          <Box className="wired-bank-info">
            <Text size="sm" />
            <Text size="sm" weight={550}>
              (FED Routing No.: 026005092)
            </Text>
          </Box>
          <Box className="wired-bank-info">
            <Text size="sm" />
            <Text size="sm" weight={550}>
              (Chips ABA No.: 0509)
            </Text>
          </Box>
        </Box>
      </Stack>
    </Card>
  </>
);

WiredInstruction.defaultProps = {
  commitmentAmount: '',
};

export default WiredInstruction;
