import React, { useState } from 'react';
import { useMantineTheme, Modal, Box, Text, Title, Checkbox } from '@mantine/core';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchData } from 'utils/apiUtils';
import backendConfig from 'config/backend.config';
import { formatNumber } from 'utils/currencySymbols';
import { InvestmentStatus } from 'shared/enums';

interface ConfirmWiredProps {
  data: {
    id: string;
    status: keyof typeof InvestmentStatus;
    total_investment: string;
  };
}

const ConfirmWired: React.FC<ConfirmWiredProps> = ({ data }) => {
  const theme = useMantineTheme();
  const { value: initialStatus } = InvestmentStatus[data.status];
  const [status, setStatus] = useState<keyof typeof InvestmentStatus>(initialStatus);
  const [opened, setOpened] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const newStatus = isChecked ? InvestmentStatus.WIRED.value : InvestmentStatus.COMMITTED.value;

    setStatus(newStatus);

    if (newStatus === InvestmentStatus.WIRED.value) {
      setOpened(true);
    }

    try {
      const statusKey = InvestmentStatus[newStatus].value;
      const token = await getAccessTokenSilently();
      await fetchData({
        url: `${backendConfig.baseUrl}v1/commitments/update`,
        method: 'PUT',
        token,
        data: { id: data.id, status: statusKey },
      });
    } catch (error) {
      console.error('Failed to update status:', error);
    }
  };

  // If status is COMPLETED, don't show the dropdown
  if (status === InvestmentStatus.COMPLETED.value || status === InvestmentStatus.FUNDS_RECEIVED.value) {
    return null;
  }

  return (
    <div style={{ color: theme.colors.red[6] }}>
      {/* {InvestmentSteps[status]} */}
      <label>
        <input type="checkbox" checked={status === InvestmentStatus.WIRED.value} onChange={handleChange} />
        <span style={{ marginLeft: '5px' }}>I have wired</span>
      </label>

      <Modal
        opened={opened}
        transition="fade"
        transitionDuration={600}
        transitionTimingFunction="ease"
        onClose={() => setOpened(false)}
        title={
          <Title order={4} weight={500} color="var(--mantine-color-primary-6)">
            Wired amount ${formatNumber(data.total_investment)}
          </Title>
        }
      >
        <Box>
          <Text fz="md" mt={10} color="var(--mantine-color-dark-6)">
            Thank you for confirming. Status will be automatically moved to <b>COMPLETED</b> once funds are received by
            Tokenfolio.
          </Text>
        </Box>
      </Modal>
    </div>
  );
};

export default ConfirmWired;
