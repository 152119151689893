import { useState, useEffect } from 'react';
import { getAuth, signInWithCustomToken, User } from 'firebase/auth';
import useCustomToken from './useCustomToken';

interface FirebaseTokenHook {
  user: User | null;
  token: string | null;
  tokenError: string | null;
}

const useFirebaseToken = (): FirebaseTokenHook => {
  const [user, setUser] = useState<User | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [tokenError, setTokenError] = useState<string | null>(null);
  const customToken = useCustomToken();

  const signInWithToken = async (ctoken: string): Promise<void> => {
    const auth = getAuth();
    try {
      const userCredential = await signInWithCustomToken(auth, ctoken);
      setUser(userCredential.user);
      const idToken = await userCredential.user.getIdToken();
      setToken(idToken);
    } catch (error: unknown) {
      if (error instanceof Error) {
        setTokenError(error.message);
      } else {
        setTokenError('An unknown error occurred');
      }
    }
  };

  useEffect(() => {
    if (customToken) {
      signInWithToken(customToken);
    }
  }, [customToken]);

  return { user, token, tokenError };
};

export default useFirebaseToken;
