const designTokens = {
  colors: {
    primary: [
      '#f5e5ff',
      '#d8b5fe',
      '#bd86fa',
      '#a355f6',
      '#8826f2',
      '#6f0ed9',
      '#5609aa',
      '#3e057a',
      '#25034b',
      '#0e001d',
    ],
    secondary: [
      '#e8eaff',
      '#bec4f8',
      '#939ced',
      '#6875e4',
      '#3e4dda',
      '#2533c1',
      '#1b2897',
      '#111d6d',
      '#081144',
      '#02051c',
    ],
  },
  typography: {
    fontSize: {
      xSmall: '10px',
      small: '14px',
      medium: '16px',
      large: '20px',
      xlarge: '24px',
      xxllarge: '28px',
    },
    fontWeight: {
      regular: 400,
      medium: 500,
      bold: 700,
    },
    headingSize: {
      h1: '32px',
      h2: '28px',
      h3: '24px',
      h4: '20px',
      h5: '16px',
      h6: '14px',
    },
  },
  spacing: {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px',
  },
  shadows: {
    small: '0px 1px 2px rgba(0,0,0,.08)',
    medium: '0px 2px 4px 2px rgba(0,0,0,.08)',
    large: '0px 4px 8px 4px rgba(0,0,0,.08)',
  },
};

export default designTokens;
