import { useState, useEffect, useCallback } from 'react';
import { fetchData } from 'utils/apiUtils';
import { useAppSelector } from 'store/hooks';
import backendConfig from 'config/backend.config';

const useCustomToken = () => {
  const [customToken, setCustomToken] = useState<string | null>(null);
  const [lastFetched, setLastFetched] = useState<number | null>(null);
  const auth0Token = useAppSelector((state) => state.auth0.token);

  const shouldFetchToken = (): boolean => {
    const oneHour = 60 * 60 * 1000;
    return !lastFetched || Date.now() - lastFetched > oneHour;
  };

  const fetchToken = useCallback(async () => {
    if (!auth0Token) return;
    try {
      const newToken = await fetchData({
        url: `${backendConfig.baseUrl}v1/auth/firebase`,
        method: 'GET',
        token: auth0Token,
      });
      setCustomToken(newToken);
      setLastFetched(Date.now());
    } catch (error) {
      console.error('Error fetching token:', error);
    }
  }, [auth0Token]);

  useEffect(() => {
    if (shouldFetchToken()) {
      fetchToken();
    }
  }, [lastFetched, fetchToken]);

  return customToken;
};

export default useCustomToken;
