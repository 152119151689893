import React from 'react';
import { useSelector } from 'react-redux';
import useToggle from 'hooks/useToggle';
import { Button, Group, Text, Title, CopyButton, Tooltip, Flex, Box, TextInput, Loader } from '@mantine/core';
import { IconMail, IconBrandLinkedin, IconCopy, IconBrandWhatsapp, IconGift } from '@tabler/icons';
import { ProductAnalytics, PaEvents } from 'analytics';
import { useGetWalletBalanceQuery, useGetReferralCodeQuery } from 'services/referral/referral.service';
import { formatNumber } from 'utils/currencySymbols';
import GiftCodeModal from './GiftCodeModal';
import GmailIcon from './Gmail';
import './Invite.scss';

// Share link and message

const referralWhatsapp = (code: string) => `
*Unlock $1,000 to Start Your Investment Journey!* 

Take advantage of exclusive pre-IPO opportunities with companies like SpaceX, xAI, ByteDance, and more.

✨ *Claim your $1,000 gift card:*
- Sign up here: https://${window.location.host}/login

- Use the code: ${code}

Don’t miss your chance to explore high-growth investment opportunities with Tokenfolio. 🚀
`;

const referralMail = (code: string) => `
Unlock $1,000 to Start Your Investment Journey!

Take advantage of exclusive pre-IPO opportunities with companies like SpaceX, xAI, ByteDance, and more.

✨ Claim your $1,000 gift card:
- Sign up here: https://${window.location.host}/login

- Use the code: ${code}

Don’t miss your chance to explore high-growth investment opportunities with Tokenfolio. 🚀
`;

interface RewardCardProps {
  setGiftCodeModalOpen: () => void;
  balance: string;
  isLoading: boolean;
}

const ShareOnLinkedIn = ({ referralCode, message }: { referralCode: string; message: string }) => {
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(message);
    ProductAnalytics.trackEvent(PaEvents.REFERRAL, {
      type: 'share_linkedin',
    });
    alert('The message has been copied to your clipboard. You can now paste it in LinkedIn Messaging.');
  };

  return (
    <Tooltip label="Share on LinkedIn" position="top" withArrow>
      <Group position="center">
        <Button
          component="a"
          href="https://www.linkedin.com/messaging/"
          target="_blank"
          rel="noopener noreferrer"
          variant="light"
          color="cyan"
          size="sm"
          onClick={handleCopyToClipboard}
        >
          <IconBrandLinkedin size={20} />
        </Button>
      </Group>
    </Tooltip>
  );
};

const CopyCodeWithTextBox = ({
  referralCode,
  referWithMessage,
  isLoading,
}: {
  referralCode: string;
  referWithMessage: string;
  isLoading: boolean;
}) => {
  // Function to handle the copy and analytics tracking
  const handleCopy = (copy: () => void) => {
    copy();
    ProductAnalytics.trackEvent(PaEvents.REFERRAL, {
      type: 'copy_code',
    });
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <TextInput
        value={referralCode}
        readOnly
        sx={{ flex: 1 }}
        styles={{
          input: { color: 'var(--mantine-color-primary-6)' },
        }}
      />
      <CopyButton value={referWithMessage}>
        {({ copied, copy }) => (
          <Tooltip label={copied ? 'Copied!' : 'Copy gifting code'} position="top" withArrow>
            <Button
              leftIcon={<IconCopy size={18} />}
              variant="outline"
              color={copied ? 'teal' : 'var(--mantine-color-primary-6)'}
              onClick={() => handleCopy(copy)}
              loading={isLoading}
              loaderPosition="right"
              sx={{ minWidth: '190px' }}
            >
              {copied ? 'Copied!' : 'Copy gift code'}
            </Button>
          </Tooltip>
        )}
      </CopyButton>
    </div>
  );
};

const RewardCard: React.FC<RewardCardProps> = ({ setGiftCodeModalOpen, balance, isLoading }) => (
  <Flex className="reward-card-wallet">
    <Box className="rewardCardBox">
      <Text size="sm">
        TF Wallet : <strong>{isLoading ? <Loader /> : `$${balance || 0}`}</strong>
      </Text>
    </Box>
    <Button variant="outline" leftIcon={<IconGift size={16} />} onClick={setGiftCodeModalOpen}>
      Have a Gift Code?
    </Button>
  </Flex>
);

// Share Buttons Component
const ShareButtons: React.FC<{ referralCode: string; isLoading: boolean }> = ({ referralCode, isLoading }) => {
  const whatappMessage = referralWhatsapp(referralCode || '');
  const message = referralMail(referralCode || '');

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    const eventType = event.currentTarget.getAttribute('data-event');
    if (eventType) {
      ProductAnalytics.trackEvent(PaEvents.REFERRAL, {
        shared_via: eventType,
      });
    }
  };

  return (
    <Group position="left" spacing="md">
      <CopyCodeWithTextBox referralCode={referralCode} referWithMessage={message} isLoading={isLoading} />

      {referralCode && (
        <>
          <Tooltip label="Share on WhatsApp" position="top" withArrow>
            <Button
              data-event="whatsapp"
              onClick={handleButtonClick}
              component="a"
              href={`https://api.whatsapp.com/send?text=${encodeURIComponent(whatappMessage)}`}
              target="_blank"
              rel="noopener noreferrer"
              variant="light"
              color="teal"
              size="sm"
            >
              <IconBrandWhatsapp size={20} />
            </Button>
          </Tooltip>

          <Tooltip label="Share via Mail" position="top" withArrow>
            <Button
              data-event="mail"
              onClick={handleButtonClick}
              component="a"
              href={`mailto:?subject=${encodeURIComponent(
                'Gift Your Network $1,000 – Earn $1,000 in Return!  🎁'
              )}&body=${encodeURIComponent(message)}`}
              variant="light"
              color="black"
              size="sm"
            >
              <IconMail size={20} />
            </Button>
          </Tooltip>

          <Tooltip label="Share on Gmail" position="top" withArrow>
            <Button
              component="a"
              data-event="gmail"
              onClick={handleButtonClick}
              href={`https://mail.google.com/mail/?view=cm&fs=1&to=&su=${encodeURIComponent(
                'Gift Your Network $1,000 – Earn $1,000 in Return!  🎁'
              )}&body=${encodeURIComponent(message)}`}
              target="_blank"
              rel="noopener noreferrer"
              variant="light"
              color="red"
              size="sm"
            >
              <GmailIcon />
              {/* <IconBrandGmail size={20} /> */}
            </Button>
          </Tooltip>

          <ShareOnLinkedIn message={message} referralCode={referralCode} />
        </>
      )}
    </Group>
  );
};

export const ReferralHeader: React.FC = () => {
  const token = useSelector((state: any) => state.auth0.token);
  const skip = !token;

  const [giftCodeModalOpen, toggleGiftModel] = useToggle(false);
  const { data: balance, isLoading: balanceLoading } = useGetWalletBalanceQuery(undefined, { skip });
  const { data: referralCode, isLoading: referralCodeLoading } = useGetReferralCodeQuery(undefined, { skip });

  return (
    <Flex direction="column" align="flex-start" gap="md">
      {/* Layout: Left = Main Content, Right = Reward Card */}
      <RewardCard
        setGiftCodeModalOpen={toggleGiftModel}
        isLoading={balanceLoading}
        balance={balance ? formatNumber(balance.amount) : '0'}
      />
      <Box>
        <Title order={2} mb="xs">
          Gift Your Network $1,000 –{' '}
          <span style={{ color: 'var(--mantine-color-primary-6)' }}>Earn $1,000 in Return! </span>
        </Title>
        <Text size="md" color="dimmed" mb="md">
          Invite your loved ones to exclusive private investments with Tokenfolio.
          <br />
          They receive $1,000, and you earn $1,000 when they make their first investment.
        </Text>
        <ShareButtons referralCode={referralCode || ''} isLoading={referralCodeLoading} />
      </Box>
      <GiftCodeModal opened={giftCodeModalOpen} onClose={toggleGiftModel} />
    </Flex>
  );
};
