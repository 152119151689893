import React from 'react';
import { Text } from '@mantine/core';
import classes from './Footer.module.scss';

const Footer: React.FC = () => (
  <footer className={classes.footer}>
    <Text size="sm" align="center" color="dimmed">
      &copy; Copyright 2021-{new Date().getFullYear()} Tokenfolio. All rights reserved.
    </Text>
  </footer>
);

export default Footer;
