import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useGetReferralListQuery } from 'services/referral/referral.service';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

export const ReferralListTable: React.FC = () => {
  const token = useSelector((state: any) => state.auth0.token);
  const skip = !token;

  const { data } = useGetReferralListQuery(undefined, { skip });

  const columnDefs: ColDef[] = [
    { headerName: 'Email', field: 'referee_email' },
    {
      headerName: 'Signup',
      field: 'signed_up',
      cellRenderer: (params: any) => (params.value ? 'Completed' : 'Pending'),
    },
    {
      headerName: 'First Investment',
      field: 'invested',
      cellRenderer: (params: any) => (params.value ? 'Completed' : 'Pending'),
    },
    {
      headerName: 'Reward',
      field: 'invested',
      cellRenderer: (params: any) => (params.value ? '$1000 Credited' : 'Pending'),
    },
  ];

  return (
    <div className="ag-theme-alpine" style={{ marginTop: '4rem' }}>
      <h2 style={{ marginBottom: '1rem', textAlign: 'center' }}>Referral Activity</h2>
      <AgGridReact
        rowData={data}
        columnDefs={columnDefs}
        domLayout="autoHeight"
        defaultColDef={{
          flex: 1,
          resizable: true,
        }}
        overlayNoRowsTemplate="No referrals have signed up"
      />
    </div>
  );
};
