export const TRANSLATE_STRING = {
  COMMON_SHARES: 'Common shares',
  PREFERRED_SHARES: 'Preferred shares',
  CONVERTIBLE_NOTE: 'Convertible note',
  PRIMARY: 'Primary',
  SECONDARY: 'Secondary',
  DIRECT: 'Direct',
  INDIRECT: 'Indirect',
  NOT_VERIFIED: 'Not verified',
  VERIFIED: 'Verified',
  UNDER_REVIEW: 'Under review',
  DENIED: 'Denied',
};
