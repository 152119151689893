import React, { useEffect, memo } from 'react';
import HelloSign from 'hellosign-embedded';
import { PaEvents, ProductAnalytics } from 'analytics';

interface HelloDocProps {
  signUrl: string;
  signatureId: string;
  onSuccess: (data: any) => void;
  onError: (error: { signatureId: string; code: 'any' }) => void;
  onCancel: () => void;
}

const HelloDoc: React.FC<HelloDocProps> = ({ signUrl, signatureId, onSuccess, onError, onCancel }) => {
  useEffect(() => {
    if (!signUrl) return undefined;

    const client = new HelloSign();
    client.open(signUrl, {
      clientId: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID || '', // Hello sign client Id
      skipDomainVerification: process.env.REACT_APP_ENV === 'dev' || false,
      allowCancel: true,
      // debug: true,
    });

    const handleSign = (data: any) => {
      ProductAnalytics.trackEvent(PaEvents.ESIGN, {
        esign_status: 'success',
      });
      onSuccess(data);
    };

    const handleCancel = () => {
      ProductAnalytics.trackEvent(PaEvents.ESIGN, {
        esign_status: 'cancelled',
      });
      onCancel();
    };

    const handleError = (error: any) => {
      ProductAnalytics.trackEvent(PaEvents.ESIGN, {
        esign_status: 'error',
      });
      onError(error);
    };

    client.on('sign', handleSign);
    client.on('cancel', handleCancel);
    client.on('error', handleError);
    return () => {
      client.off('sign', () => {});
      client.off('cancel', () => {});
    };
    // }, [signUrl, signatureId, onSuccess, onError, onCancel]);
  }, []);

  return null; // This component doesn't render anything
};

export default memo(HelloDoc);
