import React from 'react';
import { Modal, Stack, Title, Text, Button } from '@mantine/core';
import UboTable from 'components/Common/Entity/components/UboTable';
import './invest.scss';

interface DynamicMessageModalProps {
  isVisible: boolean;
  onClose: () => void;
  type: 'static-message' | 'ubo-message'; // Add more types as needed
  data?: any;
}

export const MessageModal: React.FC<DynamicMessageModalProps> = ({ isVisible, onClose, type, data }) => {
  const renderContent = () => {
    switch (type) {
      case 'static-message':
        return (
          <Stack>
            <Title order={3} weight={550}>
              We are waiting on your documents
            </Title>

            <Title order={4} weight={550}>
              For investing via {data?.legal_name}, we need the following for regulatory purposes:
            </Title>

            <Text mt={4} mb={-15}>
              1. A simple declaration from you stating:
            </Text>

            <Text size="sm" mt={4}>
              - The entity has not been formed solely for the transaction being contemplated currently.
            </Text>
            <Text size="sm" mt={-15}>
              - The entity has assets worth $5M USD; OR all shareholders of the entity are individually accredited
              investors as per SEC guidelines.
            </Text>

            <Text mt={4} mb={-15}>
              2. Certificate of incorporation, passport, and address proof for anyone with a claim to 25% or more of the
              entity&apos;s assets, as well as a control person for the entity.
            </Text>

            <Text mt={8}>
              <b>Please email them to </b>
              <a href="mailto:support@tokenfolio.co">support@tokenfolio.co</a>.
            </Text>
          </Stack>
        );

      case 'ubo-message':
        return (
          <Stack>
            <Title order={3} weight={550}>
              KYB Verification Completed
            </Title>
            <Text>
              Your KYB verification process has been successfully completed. However, we are still waiting for the
              following UBO verifications to be finalized. Please ensure all UBOs complete their verification at the
              earliest to proceed with your investment.
            </Text>

            <Text mt={4} mb={4}>
              An email has been sent to the following UBOs for KYC:
            </Text>

            <UboTable showStatusColumn ubos={data?.ubos} />
          </Stack>
        );

      default:
        return <Text>No content available for this type.</Text>;
    }
  };

  return (
    <>
      {renderContent()}
      <div className="footer-button">
        <Button className="ok-button" onClick={onClose} variant="filled" color="green">
          OK
        </Button>
      </div>
    </>
  );
};

MessageModal.defaultProps = {
  data: undefined,
};

const DynamicMessageModal: React.FC<DynamicMessageModalProps> = ({ isVisible, onClose, type, data }) => (
  <Modal opened={isVisible} onClose={onClose} size="50%">
    <MessageModal isVisible={isVisible} onClose={onClose} type={type} data={data} />
  </Modal>
);

DynamicMessageModal.defaultProps = {
  data: undefined,
};

export default DynamicMessageModal;
