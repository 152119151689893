import React from 'react';
import { ReferralHeader } from './ReferralHeader';
import { HowItWorksSection } from './HowItWorksSection';
import { ReferralListTable } from './ReferralListTable';
import FAQ from './FAQ';

const ReferralPage: React.FC = () => (
  <div style={{ padding: '2rem', maxWidth: '1200px', margin: 'auto' }}>
    {/* Referral Header */}
    <ReferralHeader />

    {/* Referral List Table */}
    <ReferralListTable />

    {/* How It Works Section */}
    <HowItWorksSection />

    <FAQ />
  </div>
);

export default ReferralPage;
