import React from 'react';
import { Avatar, Badge, Card, Group, Image, Text, Title, useMantineTheme, Box } from '@mantine/core';
import moment from 'moment';
import './CompanyCard.scss';
import { CompanyOverview } from 'models/company/company.model';
import { CompanyTag } from 'models/company/company.model.enums';

type Props = Pick<
  CompanyOverview,
  | 'bannerUrl'
  | 'location'
  | 'logoUrl'
  | 'name'
  | 'summary'
  | 'tags'
  | 'dealEnd'
  | 'dealStart'
  | 'external'
  | 'external_platform'
>;

const CompanyCard: React.FC<Props> = ({
  bannerUrl,
  location,
  logoUrl,
  name,
  summary,
  tags,
  dealEnd,
  dealStart,
  external,
  external_platform,
}) => {
  const theme = useMantineTheme();

  // Determine deal status
  const getDealStatus = () => {
    const now = moment();
    const end = moment(dealEnd, 'YYYY-MM-DD').endOf('day');
    const start = moment(dealStart, 'YYYY-MM-DD');

    if (end.isBefore(now)) return 'CLOSED';
    if (start.isBefore(now)) return 'LIVE';
    return 'ANNOUNCED';
  };

  const dealStatus = getDealStatus();

  // Define badge colors based on deal status
  const statusColorMap = {
    LIVE: theme.colors.green[6],
    CLOSED: theme.colors.red[6],
    ANNOUNCED: theme.colors.gray[6],
  };

  return (
    <Card className="company-card" p="lg" withBorder>
      <Card.Section>
        <Image className="company-card__cover-photo" src={bannerUrl} alt={name} height={200} withPlaceholder />
        <Avatar
          className="company-card__logo"
          src={logoUrl}
          alt={name}
          size="lg"
          mt={-theme.spacing.lg * 2}
          ml={theme.spacing.lg}
        />
      </Card.Section>
      <Box className="company-details">
        <Title order={4} my="sm">
          {name}
        </Title>

        <Text className="company-card__summary" mb="lg" color="gray.7" lineClamp={4}>
          {summary}
        </Text>
      </Box>

      <Box>
        {external && (
          <Text size="sm" my="xs" color="red">
            {`Live on our partner ${external_platform ?? external_platform} platform.`}{' '}
          </Text>
        )}
        <Text size="sm" my="xs" color="dimmed">
          {location.city}, {location.country}
        </Text>

        <Group spacing="sm">
          <Badge radius="sm" style={{ backgroundColor: statusColorMap[dealStatus], color: 'white' }}>
            {dealStatus}
          </Badge>
          {tags.map((tag) => {
            const tagValue = CompanyTag[tag as keyof typeof CompanyTag] || tag;
            return (
              <Badge key={tag} radius="sm">
                {tagValue}
              </Badge>
            );
          })}
        </Group>
      </Box>
    </Card>
  );
};

export default CompanyCard;
