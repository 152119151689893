import React from 'react';
import { Box, Title, Text, Button } from '@mantine/core';

const NewInvestmentMessage = ({ onNewEntityClick }: { onNewEntityClick: () => void }) => (
  <Box className="new-investment-message">
    <Title order={2}> We need a little more information to complete your first investment. </Title>
    <Text color="grey"> Set up your investment entity to use now and on future investment</Text>

    <Button onClick={onNewEntityClick} mt="lg">
      {' '}
      Set up investing entity
    </Button>
  </Box>
);

export default NewInvestmentMessage;
