import React from 'react';
import { Box, Title, List } from '@mantine/core';

const FirstTimeInvestor = () => (
  <Box
    sx={(theme) => ({
      backgroundColor: 'rgb(245 229 255 / 100%)',
      padding: theme.spacing.lg,
      borderRadius: theme.radius.md,
      marginTop: theme.spacing.md,
    })}
  >
    <Title order={4} weight={500} mb={10}>
      First time investors, please read:
    </Title>
    <List size="sm">
      <List.Item>I acknowledge that Tokenfolio will not be responsible for any of my investment losses.</List.Item>
      <List.Item>I understand that many startups fail and I may lose my entire investment.</List.Item>
      <List.Item>
        I understand that my interests may be diluted in any subsequent company financing and I do not have a right to
        participate in such financing.
      </List.Item>
      <List.Item>I understand that I cannot rely on any forward-looking statements regarding any startup.</List.Item>
    </List>
  </Box>
);

export default FirstTimeInvestor;
