const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const monthsAbbr = ['Jan', 'Feb', 'March', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

// eslint-disable-next-line import/prefer-default-export
export const toUIDate = (date: Date, abbr?: boolean) => {
  const day = date.getDay();
  const monthIndex = date.getMonth();
  const month = abbr ? monthsAbbr[monthIndex] : months[monthIndex];
  const year = date.getFullYear();
  return `${month} ${day}, ${year}`;
};

type DateFormat = 'day-date' | 'full' | 'time' | 'date';

export const formatDate = (dateString: string, format: DateFormat): string => {
  const date = new Date(dateString);

  switch (format) {
    case 'day-date':
      return date.toLocaleDateString('en-US', { weekday: 'short', day: '2-digit', month: 'short' });
    case 'full':
      return date.toLocaleString('en-US', {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
    case 'time':
      return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    case 'date':
      return date.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' });
    default:
      return date.toISOString();
  }
};

export enum FieldTypesEnum {
  Currency,
  Percentage,
  DateTime,
  String,
  Link,
  Translate,
}
