import { CompanyApi, CompanyListApi } from 'models/company/company.api.model';
import { Company, CompanyOverview } from 'models/company/company.model';
import { BannerType, CompanyPitchSection, CompanyTag, InvestmentSecurity } from 'models/company/company.model.enums';

const companyTags = Object.values(CompanyTag);
const companyPitchSections = Object.values(CompanyPitchSection);

const mapTagsApiToCompanyTags = (apiTags: CompanyApi['company']['tag']): CompanyTag[] =>
  apiTags.reduce<CompanyTag[]>(
    (tags, tag) => (companyTags.includes(tag as CompanyTag) ? [...tags, tag as CompanyTag] : tags),
    []
  );

const mapCompanySectionsApiToCompanySections = (apiSections: CompanyApi['company']['section']): Company['sections'] =>
  apiSections.reduce<Company['sections']>(
    (sections, section) =>
      companyPitchSections.includes(section.type as CompanyPitchSection)
        ? [...sections, { ...section, type: section.type as CompanyPitchSection }]
        : sections,
    []
  );

export const mapCompanyApiToCompany = ({ company }: CompanyApi): Company => ({
  id: company.id,
  name: company.name,
  summary: company.summary,
  location: company.location,
  founders: {
    founder: company.founders?.founder,
  },
  documents: {
    ...company.documents,
  },
  socials: {
    ...company.socials,
  },
  bannerUrl: {
    ...company.bannerURL,
    type: company.bannerURL.type as BannerType,
  },
  logoUrl: company.logoURL,
  tags: mapTagsApiToCompanyTags(company.tag),
  highlights: company.highlight,
  sections: mapCompanySectionsApiToCompanySections(company.section),
  dealTerms: {
    ...company.dealTerms,
    deadline: new Date(company.dealTerms.deadline).toString(),
    security: company.dealTerms.security as InvestmentSecurity,
  },
});

export const mapCompanyListApiToCompanyList = (companyListApi: CompanyListApi): CompanyOverview[] => {
  const companyOverviewListApi = companyListApi.companyList.companyOverview;
  return companyOverviewListApi.map((company) => ({
    id: company.id,
    bannerUrl: company.bannerURL,
    location: company.location,
    logoUrl: company.logoURL,
    name: company.name,
    summary: company.summary,
    tags: mapTagsApiToCompanyTags(company.tag),
    dealEnd: company.dealEnd,
    dealStart: company.dealStart,
    hasDetailsPage: company.hasDetailsPage === 'true',
  }));
};
