import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Auth0State {
  token: string | null;
  user: any; // You can replace `any` with a more specific type if needed
  investments: Record<string, number>; // Add this line to store investments by dealId
}

const initialState: Auth0State = {
  token: null,
  user: null,
  investments: {}, // Initialize investments
};

const auth0Slice = createSlice({
  name: 'auth0',
  initialState,
  reducers: {
    setAuthContext(state, action: PayloadAction<{ token: string | null; user: any }>) {
      state.token = action.payload.token;
      state.user = action.payload.user;
    },
    updateInvestment(state, action: PayloadAction<{ dealId: string; investmentValue: number }>) {
      state.investments[action.payload.dealId] = action.payload.investmentValue;
    },
  },
});

export const { actions, reducer: auth0Reducer } = auth0Slice;
export const { setAuthContext, updateInvestment } = actions;
