import React from 'react';
import { Group, Drawer, HoverCard, Button, Burger, MediaQuery, useMantineTheme } from '@mantine/core';
import { PATH_ROOT, PATH_SETTINGS_BASIC, PATH_INVITE_FRIENDS } from 'shared/constants';
import logo from 'assets/tokenfolio-black.svg';
import { Link, NavLink } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { selectShouldConfirm } from 'pages/settings/SettingsSlice';
import { IconGift } from '@tabler/icons';
import TopNavigationRightAuthenticated from './TopNavigationRightAuthenticated';
import classes from './TopNavigation.module.scss';

const BonusWithDashboardButton = ({ isDisabled }: { isDisabled: boolean }) => (
  <HoverCard position="bottom" withArrow shadow="md" disabled={!isDisabled} withinPortal>
    <HoverCard.Target>
      <NavLink to={isDisabled ? '#' : PATH_INVITE_FRIENDS}>
        <Button variant="outline" disabled={isDisabled} leftIcon={<IconGift />} radius="xl">
          Get $1000
        </Button>
      </NavLink>
    </HoverCard.Target>
    <HoverCard.Target>
      <NavLink to={isDisabled ? '#' : '/dashboard'}>
        <Button variant="subtle" disabled={isDisabled}>
          Dashboard
        </Button>
      </NavLink>
    </HoverCard.Target>
    <HoverCard.Dropdown>
      <div style={{ width: 400 }}>
        Complete identity verification in the Basic Settings to access the dashboard.{' '}
        <Link to={PATH_SETTINGS_BASIC} style={{ textDecoration: 'underline' }}>
          Click here to proceed.
        </Link>{' '}
      </div>
    </HoverCard.Dropdown>
  </HoverCard>
);

const TopNavigation: React.FC = () => {
  const theme = useMantineTheme();
  const hasDashboardAccess = useAppSelector(selectShouldConfirm);
  const [opened, setOpened] = React.useState(false);
  const title = opened ? 'Close navigation' : 'Open navigation';

  const MenuContent = (
    <Group style={{ display: 'flex', flexDirection: opened ? 'column-reverse' : 'row' }}>
      <BonusWithDashboardButton isDisabled={hasDashboardAccess} />
      <TopNavigationRightAuthenticated />
    </Group>
  );

  return (
    <header className={classes.header}>
      <div className={classes.inner}>
        <Link to={PATH_ROOT}>
          <img className={classes.logo} src={logo} alt="Tokenfolio" />
        </Link>
        <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
          <Burger
            opened={opened}
            onClick={() => setOpened((o) => !o)}
            size="sm"
            color={theme.colors.gray[6]}
            mr="xl"
            title={title}
          />
        </MediaQuery>
        <MediaQuery smallerThan="sm" styles={{ display: 'none !important' }}>
          {MenuContent}
        </MediaQuery>
        <Drawer opened={opened} onClose={() => setOpened(false)} position="right" padding="sm" size="sm">
          {MenuContent}
        </Drawer>
      </div>
    </header>
  );
};

export default TopNavigation;
