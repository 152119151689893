import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { InvestmentStatus } from 'shared/enums';
import { IconFileDownload } from '@tabler/icons';
import { ActionIcon, Tooltip } from '@mantine/core';
import { PaEvents, ProductAnalytics } from 'analytics';
import { fetchData } from 'utils/apiUtils';
import useFirebaseToken from 'hooks/useFirebaseToken';

interface SubscriptionLinkProps {
  data: {
    deal_id: string;
    signature_request_id: string;
    status: keyof typeof InvestmentStatus;
  };
  value: string;
}

const SubscriptionLink: React.FC<SubscriptionLinkProps> = ({ data, value }) => {
  const { user: authenticatedUser } = useAuth0();
  const { token } = useFirebaseToken();

  const { deal_id: dealID, signature_request_id: signatureRequestID, status } = data;
  const signatureID = value;

  const investmentStatus = InvestmentStatus[status];
  const isAcceptanceDocumentAvailable = investmentStatus.value === InvestmentStatus.COMPLETED.value;

  const firebaseBaseURL = `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o`;
  const userDocumentsPath = `users%2Fdocuments%2F${authenticatedUser?.sub}%2F${dealID}`;

  const subscriptionDocumentURL = `${firebaseBaseURL}/${userDocumentsPath}%2F${signatureID}.pdf?alt=media`;
  const acceptanceDocumentURL = `${firebaseBaseURL}/${userDocumentsPath}%2F${signatureRequestID}.pdf?alt=media`;

  const downloadFile = async (url: string, fileName: string) => {
    try {
      const response = await fetchData({ url, method: 'GET', token, responseType: 'blob' });
      const fileBlob = new Blob([response]);
      const fileUrl = window.URL.createObjectURL(fileBlob);
      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error fetching document:', error);
    }
  };

  const downloadSubscriptionDocument = () => {
    const fileName = `subscription-document_${dealID}_${signatureID}.pdf`;
    downloadFile(subscriptionDocumentURL, fileName);
    ProductAnalytics.trackEvent(PaEvents.USER_DOCUMENTS, {
      info_type: 'subscription-document',
      deal_id: dealID,
      signature_id: signatureID,
    });
  };

  const downloadAcceptanceDocument = () => {
    const fileName = `acceptance-document_${dealID}_${signatureRequestID}.pdf`;
    downloadFile(acceptanceDocumentURL, fileName);
    ProductAnalytics.trackEvent(PaEvents.USER_DOCUMENTS, {
      info_type: 'acceptance-document',
      deal_id: dealID,
      signature_request_id: signatureRequestID,
    });
  };

  return (
    <div style={{ display: 'flex', gap: 5, alignItems: 'center', height: '100%' }}>
      <Tooltip label="Download Subscription Document" withinPortal>
        <ActionIcon variant="subtle" onClick={downloadSubscriptionDocument} color="blue">
          <IconFileDownload size={20} />
        </ActionIcon>
      </Tooltip>

      {isAcceptanceDocumentAvailable && (
        <Tooltip label="Download Acceptance Document" withinPortal>
          <ActionIcon variant="subtle" onClick={downloadAcceptanceDocument} color="purple">
            <IconFileDownload size={20} />
          </ActionIcon>
        </Tooltip>
      )}
    </div>
  );
};

export default SubscriptionLink;
