import React from 'react';
import { Modal } from '@mantine/core';
import PersonaInquiry from './PersonaInquiry';

interface PersonaComponentProps {
  inquiryId: string;
  sessionToken: string;
  onComplete: () => void;
  onCloseModel: () => void;
  opened: boolean;
}

const PersonaComponent: React.FC<PersonaComponentProps> = ({
  inquiryId,
  sessionToken,
  onComplete,
  onCloseModel,
  opened,
}) => (
  <Modal
    opened={opened}
    onClose={onCloseModel}
    title="Verify your Information"
    size="50%"
    overlayBlur={3}
    overlayOpacity={0.55}
  >
    <div className="personna-frame">
      <PersonaInquiry inquiryId={inquiryId} sessionToken={sessionToken} onComplete={onComplete} />
    </div>
  </Modal>
);

export default PersonaComponent;
