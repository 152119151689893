import { createApi } from '@reduxjs/toolkit/query/react';
import { RootState } from 'store/store';
import { createBaseQuery } from 'utils/apiUtils';

export const referralApi = createApi({
  reducerPath: 'referralApi',
  tagTypes: ['WalletBalance'],
  baseQuery: async (args, api, extraOptions) => {
    const { token } = (api.getState() as RootState).auth0;
    const baseQuery = createBaseQuery(token);
    return baseQuery(args, api, extraOptions);
  },
  endpoints: (builder) => ({
    getWalletBalance: builder.query<{ amount: number }, void>({
      query: () => '/wallets',
      providesTags: ['WalletBalance'],
    }),
    getReferralCode: builder.query<string, void>({
      query: () => ({
        url: '/referral-codes',
        method: 'GET',
        responseHandler: (response: { text: () => any }) => response.text(),
      }),
    }),
    applyGiftCode: builder.mutation<void, { code: string }>({
      query: ({ code }) => ({
        url: `referrals?code=${code}`,
        method: 'PUT',
        responseHandler: (response: { text: () => any }) => response.text(),
      }),
      invalidatesTags: ['WalletBalance'],
    }),
    getReferralList: builder.query<any[], void>({
      query: () => '/referrals',
    }),
  }),
});

export const { useGetWalletBalanceQuery, useGetReferralCodeQuery, useApplyGiftCodeMutation, useGetReferralListQuery } =
  referralApi;
