import React from 'react';

const GmailIcon = () => (
  <svg width="24" height="24" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    {/* <rect width="48" height="48" rx="8" fill="#fff" /> */}
    <path fill="#EA4335" d="M6 12v24h12V24l6 4.5L30 24v12h12V12L24 24z" />
    <path fill="#FBBC05" d="M24 24L6 12h36z" />
    <path fill="#34A853" d="M6 12v24l12-12z" />
    <path fill="#4285F4" d="M42 12v24L30 24z" />
  </svg>
);

export default GmailIcon;
