// src/components/Common/DetailsContainer.tsx
import React from 'react';
import { Box, Text, Group, Title } from '@mantine/core';

type Field = {
  label: string;
  value: any;
};

type Props = {
  field: Field;
  className?: string;
};

const CardTableView: React.FC<Props> = ({ field, className }) => (
  <Group position="apart" mt={5} mb={5} className={`card-container ${className}`}>
    <Text className="card-label" fw={550} style={{ textAlign: 'left', color: 'var(--mantine-color-dark-5)' }} size="sm">
      {field.label}
    </Text>
    <Text className="card-value" color="dimmed" size="sm" fw={550} style={{ textAlign: 'right' }} mt={6}>
      {field.value}
    </Text>
  </Group>
);

CardTableView.defaultProps = {
  className: '',
};

export default CardTableView;
