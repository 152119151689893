import React, { useState } from 'react';
import { Stack, Title, Stepper, Text, Card, Box, Modal } from '@mantine/core';
import { IDeals } from 'features/Deals';
import { formatNumberWithCommas } from 'utils/currencySymbols';
import InvestmentInfo from 'components/Common/InvestmentInfo';
import WiredInstruction from './WiredInstruction';

interface WiringComponentProps {
  isOpen: boolean;
  activeStage: number;
  amount?: number;
  currency: string;
  handleClose: () => void;
  data: IDeals;
  showDealsTerm?: boolean;
}

const WiringComponent: React.FC<WiringComponentProps> = ({
  isOpen,
  handleClose,
  activeStage,
  amount,
  currency,
  data,
  showDealsTerm,
}) => {
  const commitmentAmount = formatNumberWithCommas(amount || 0);
  return (
    <Modal opened={isOpen} onClose={handleClose} size={!showDealsTerm ? '50%' : '80%'}>
      <Box className="company-details-container">
        <Box className="left-column" style={{ flex: `${!showDealsTerm ? '1' : '0 0 60%'}` }}>
          <Stack>
            <Title order={3} weight={550}>
              We are waiting on your funds
            </Title>
            <Text color="dimmed" mt={-15}>
              Complete the steps below to finish the investment in {data?.company_name}.
            </Text>
            <Text mt={4}>
              You have signed for {currency}
              {commitmentAmount} commitment.
            </Text>
            <Stepper active={activeStage} breakpoint="md" size="md" my={10}>
              <Stepper.Step label="E-Sign" />
              <Stepper.Step label="Wire funds">
                <WiredInstruction commitmentAmount={commitmentAmount} />
              </Stepper.Step>
              <Stepper.Step label="Investment complete">
                <>
                  <Text>
                    No further action required - we will email you when the funds arrive and let you know when the deal
                    closes.
                  </Text>
                  <WiredInstruction />
                </>
              </Stepper.Step>
              <Stepper.Completed>
                <Text size="md"> No further action required - we will email you when the deal closes.</Text>
              </Stepper.Completed>
            </Stepper>
          </Stack>
        </Box>
        {showDealsTerm && (
          <Box className="company-deals-term">
            <InvestmentInfo data={data} committedAmount={amount || 0} showWalletBalance={false} />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

WiringComponent.defaultProps = {
  amount: 0,
  showDealsTerm: true,
};

export default WiringComponent;
