import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tabs } from '@mantine/core';
import { IconUser } from '@tabler/icons';
import './SettingsNavigation.scss';
import { selectCurrentView, setCurrentView } from 'pages/settings/SettingsSlice';
import { SettingsView } from 'pages/settings/enum';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const SettingsNavigation: React.FC = () => {
  const navigate = useNavigate();

  const currentView = useAppSelector(selectCurrentView);

  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    const view = location.pathname.split('/')[2];
    dispatch(setCurrentView(view as SettingsView));
  }, [dispatch, location.pathname]);

  const handleTabChange = useCallback(
    (view: SettingsView) => {
      dispatch(setCurrentView(view));
      navigate(view);
    },
    [dispatch, navigate]
  );

  return (
    <Tabs className="settings-navigation" value={currentView} onTabChange={handleTabChange}>
      <Tabs.List>
        <Tabs.Tab
          key="basic"
          icon={<IconUser size={24} stroke={2} />}
          className="settings-navigation__tab"
          value="basic"
        >
          Basic Info
        </Tabs.Tab>
      </Tabs.List>
    </Tabs>
  );
};

export default SettingsNavigation;
