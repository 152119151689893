import React, { useState, useEffect, useCallback } from 'react';
import { Stepper, Button, Group, Box, TextInput, Radio, Title, Select, Text } from '@mantine/core';
import { useGetCountriesQuery, useCreateEntityMutation } from 'services/invest/entities.service';
import { IconCircleX } from '@tabler/icons';
import useToggle from 'hooks/useToggle';
import { IUboType } from 'models/invest/entity.api.model';
import { formConfig } from './formConfig'; // Form configuration for fields and accreditation
import RadioCard from '../../RadioCard/RadioCard';
import { toast } from 'react-toastify';
import { EntityType } from 'shared/enums';
import UBOFileds from './UBOFields';
import UboTable from './UboTable';
import EntityCreated from './EntityCreated';
import { Entity } from 'models/invest/entity.api.model';
import './Entity.scss';

interface EntityStepperProps {
  entityType: string | null;
  initialData: any;
  setOpened: (opened: boolean) => void;
  onKycVerificationStart: (entity: Entity | null) => void;
}

const createUBOEmptyObj = { first_name: '', last_name: '', email: '', country: '', share_holding: 0, type: 'UBO' };
let apiResponseEntity: Entity | null = null;

const EntityStepper: React.FC<EntityStepperProps> = ({
  entityType,
  initialData,
  setOpened,
  onKycVerificationStart,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    type: entityType || '',
    ...initialData,
  });
  const [completedSteps, setCompletedSteps] = useState<boolean[]>([false, false, false, true]);
  const [showMessage, toggleMessage, setShowMessage] = useToggle(false);

  const { data: countries, isLoading } = useGetCountriesQuery();
  const [ubos, setUbos] = useState<IUboType[]>([createUBOEmptyObj]);

  const [createEntity, { isLoading: createLoading }] = useCreateEntityMutation();
  const { fields, accreditation } = formConfig[formData.type || 'INDIVIDUAL'];
  const [touched, setTouched] = useState<boolean>(false); // New state to track if "Next" is clicked

  useEffect(() => {
    setFormData({ type: entityType || '', ...initialData });
    setActiveStep(0);
    setCompletedSteps([false, false, entityType === 'INDIVIDUAL', true]);
  }, [entityType, initialData]);

  const validateUBOs = () =>
    ubos.every((ubo) => Object.values(ubo).every((value) => (typeof value === 'string' ? value.trim() !== '' : value)));

  // Determine the total number of steps based on the entity type
  const totalSteps = formData.type === 'COMPANY' ? 4 : 3;
  const validateStep = () => {
    if (activeStep === 0) {
      return fields.every((field) => !field.required || formData[field.name]?.trim());
    }
    if (activeStep === 1) {
      return Boolean(formData.accreditation);
    }
    if (activeStep === 2 && formData.type === 'COMPANY') {
      return validateUBOs();
    }
    return true;
  };

  const isSubmitDisabled = () => completedSteps.includes(false);
  const nextStep = () => {
    setTouched(true);
    if (validateStep()) {
      setCompletedSteps((prev) => {
        const newCompletedSteps = [...prev];
        newCompletedSteps[activeStep] = true;
        return newCompletedSteps;
      });
      setActiveStep((current) => (current < totalSteps - 1 ? current + 1 : current));
      setTouched(false);
    } else {
      setCompletedSteps((prev) => {
        const newCompletedSteps = [...prev];
        newCompletedSteps[activeStep] = false;
        return newCompletedSteps;
      });
    }
  };

  const prevStep = () => setActiveStep((current) => (current > 0 ? current - 1 : current));

  const handleInputChange = (field: string, value: string) => {
    setFormData({ ...formData, [field]: value });
  };

  // Memoized handleUBOChange function
  const handleUBOChange = useCallback(
    (index: number, field: keyof IUboType, value: string | number) => {
      setUbos((prev) => prev.map((ubo, i) => (i === index ? { ...ubo, [field]: value } : ubo)));
    },
    [] // Empty dependency
  );

  const handleAddUBO = () => setUbos([...ubos, { ...createUBOEmptyObj }]);
  const handleRemoveUBO = (index: number) => setUbos((prev) => prev.filter((_, i) => i !== index));

  const handleSubmit = async () => {
    if (validateStep()) {
      try {
        const entity: Entity = await createEntity({ ...formData, ubos }).unwrap(); // Call the mutation
        apiResponseEntity = entity;
        // if(formData.type !== 'INDIVIDUAL') {
        //   setShowMessage(true);
        // } else {
        //   setOpened(false);
        //   toast.success(`Your entity is been created successfully`);
        // }

        setShowMessage(true);
      } catch (error) {
        toast.error(`Failed to create entity. Please try after sometime`);
      }
    }
  };

  const onCardClick = (value: string) => {
    console.log(' *** onCardClick', value);
  };

  if (showMessage && apiResponseEntity)
    return (
      <EntityCreated
        onClose={() => setOpened(false)}
        entity={apiResponseEntity}
        onKycVerificationStart={() => onKycVerificationStart(apiResponseEntity)}
      />
    );

  return (
    <div className="stepper-container">
      <div className="stepper-header">
        <Stepper active={activeStep} onStepClick={setActiveStep} breakpoint="sm">
          <Stepper.Step
            label="Basic Details"
            color={completedSteps[0] ? 'green' : 'red'}
            completedIcon={completedSteps[0] ? undefined : <IconCircleX />}
          />
          <Stepper.Step
            label="Accreditation"
            color={completedSteps[1] ? 'green' : 'red'}
            completedIcon={completedSteps[1] ? undefined : <IconCircleX />}
          />
          {formData.type === 'COMPANY' && (
            <Stepper.Step
              label="UBO Details"
              color={completedSteps[2] ? 'green' : 'red'}
              completedIcon={completedSteps[2] ? undefined : <IconCircleX />}
            />
          )}
          <Stepper.Step
            label="Review & Submit"
            color={completedSteps[3] ? 'green' : 'red'}
            completedIcon={completedSteps[3] ? undefined : <IconCircleX />}
          />
        </Stepper>
      </div>

      <div className="step-content">
        {activeStep === 0 && (
          <Box>
            {fields.map((field) => (
              <TextInput
                key={field.name}
                label={field.label}
                value={formData[field.name] || ''}
                onChange={(e) => handleInputChange(field.name, e.target.value)}
                required={field.required}
                error={touched && field.required && !formData[field.name]?.trim() ? 'This field is required' : null}
              />
            ))}
            <Select
              label={formData.type === 'INDIVIDUAL' ? 'Country' : 'Country of incorporation'}
              placeholder="Select Country"
              searchable
              nothingFound="No options"
              data={isLoading ? [] : countries || []}
              value={formData.country || ''}
              onChange={(value: string) => handleInputChange('country', value)}
              required
              error={touched && !formData.country ? 'This field is required' : null}
            />
          </Box>
        )}

        {activeStep === 1 && (
          <Box>
            <Title order={5}>How are you accredited?</Title>
            <Radio.Group
              value={formData.accreditation || ''}
              onChange={(value) => handleInputChange('accreditation', value)}
              error={touched && !formData.accreditation ? 'This field is required' : null}
              spacing="sm"
            >
              {accreditation.map((option) => (
                <div key={option.value} style={{ width: '100%' }}>
                  <RadioCard
                    value={option.value}
                    title={option.label}
                    description={option.description}
                    onStatusClick={() => onCardClick(option.value)}
                    isSelected={formData.accreditation === option.value}
                    onChange={(value) => handleInputChange('accreditation', value)}
                  />
                </div>
              ))}
            </Radio.Group>
          </Box>
        )}

        {activeStep === 2 && formData.type === 'COMPANY' && (
          <UBOFileds
            ubos={ubos}
            countries={countries}
            onAddUBO={handleAddUBO}
            onRemoveUBO={handleRemoveUBO}
            onChangeUBO={handleUBOChange}
          />
        )}

        {(activeStep === 2 && formData.type === 'INDIVIDUAL') || activeStep === 3 ? (
          <Box>
            <p>
              <strong>Entity Type:</strong> {EntityType[formData.type as keyof typeof EntityType].label}
            </p>
            {fields.map((field) => (
              <p key={field.name}>
                <strong>{field.label}:</strong> {formData[field.name]}
              </p>
            ))}
            <p>
              <strong>Country:</strong>{' '}
              {countries?.find((data: { label: string; value: string }) => data.value === formData.country)?.label}
            </p>

            {formData.type === 'COMPANY' && ubos && <UboTable ubos={ubos} />}

            <Text color="red">
              {' '}
              <i>Note:</i> Please verify the information carefully, as it cannot be modified once submitted.
            </Text>
          </Box>
        ) : null}
      </div>

      <div className="stepper-footer">
        <Group position="right" mt="md">
          {activeStep > 0 && <Button onClick={prevStep}>Back</Button>}
          {activeStep < totalSteps - 1 ? (
            <Button onClick={nextStep}>Next</Button>
          ) : (
            <Button onClick={handleSubmit} disabled={isSubmitDisabled()} loading={createLoading}>
              Submit
            </Button>
          )}
        </Group>
      </div>
    </div>
  );
};

export default EntityStepper;
