import React from 'react';
import { Notification, Text, Box, Divider, Modal } from '@mantine/core';
import WiringComponent from 'components/Common/Wired/WiringComponent';
import ConfirmWired from 'features/Dashboard/components/ConfirmWired';
import { formatNumber } from 'utils/currencySymbols';
import { InvestmentStatus } from 'shared/enums';

// Stateless component for rendering individual notifications with checkbox
const CommitmentNotification = ({ data, onOpenModal }: any) => (
  <Box
    sx={(theme) => ({
      padding: theme.spacing.sm,
      borderRadius: theme.radius.md,
      display: 'flex',
      gap: '10px',
      alignItems: 'center',
      color: 'black',
    })}
  >
    <div>
      Wiring amount for <b>{data.company_name}</b> is pending for $<b>{formatNumber(data.total_investment)}</b>
    </div>
    {data && <ConfirmWired data={data} />}
    <Text
      fz="md"
      color="var(--mantine-color-primary-6)"
      sx={{ cursor: 'pointer', marginLeft: 'auto' }}
      onClick={() => onOpenModal(data)}
    >
      Wiring info
    </Text>
  </Box>
);

// Modal component for wiring details
export const WiringModal = ({ opened, onClose, commitment }: any) => {
  const value = InvestmentStatus.COMMITTED.rank;

  return (
    <Modal opened={opened} onClose={onClose} title="Wiring Information">
      {commitment && (
        <WiringComponent
          isOpen={opened}
          handleClose={onClose}
          activeStage={value}
          amount={commitment.total_investment}
          currency="$"
          data={{ ...commitment, company_name: commitment.company }}
          showDealsTerm={false}
        />
      )}
    </Modal>
  );
};

// Main component for handling the notification logic
export const NotificationWrapper = ({ commitments, openModal, onClose }: any) => (
  <Notification
    style={{ alignItems: 'baseline' }}
    title={
      <Text fz="md" color="#FF6B6B">
        Action required
      </Text>
    }
    color="red"
    mb="md"
    onClose={onClose}
  >
    {commitments?.map((data: any, index: number) => (
      <React.Fragment key={data.id}>
        <CommitmentNotification data={data} onOpenModal={openModal} />
        {index !== commitments.length - 1 && <Divider />}
      </React.Fragment>
    ))}
  </Notification>
);
