import React from 'react';
import Persona from 'persona';

interface PersonaInquiryProps {
  inquiryId: string;
  sessionToken: string;
  onComplete: () => void;
}

const PersonaInquiry: React.FC<PersonaInquiryProps> = ({ inquiryId, sessionToken, onComplete }) => (
  <div className="personna-frame">
    <Persona.Inquiry
      inquiryId={inquiryId}
      sessionToken={sessionToken}
      environmentId={process.env.REACT_APP_PERSONA_ENVIRONMENT_ID}
      onComplete={onComplete}
      onCancel={onComplete}
    />
  </div>
);

export default PersonaInquiry;
