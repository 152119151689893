/**
 * !!! DISCLAIMER: DO NOT USE !!!
 * this epic was only used to set up and test redux setup
 */
import { Action } from '@reduxjs/toolkit';
import { combineEpics } from 'redux-observable';
import { catchError, EMPTY, filter, Observable, switchMap } from 'rxjs';

import { load } from './CompanySlice';

const loadEpic = (action$: Observable<Action<typeof load>>) =>
  action$.pipe(
    filter(load.match),
    switchMap(() => EMPTY),
    catchError((error, caught) => {
      // eslint-disable-next-line no-console
      console.error(error);
      return caught;
    })
  );

export default combineEpics(loadEpic);
