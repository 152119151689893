import React, { useEffect } from 'react';
import withPublicLayout from 'hocs/withPublicLayout';
import DashboardPortfolio from 'features/Dashboard/components/DashboardPortfolio';

import { ProductAnalytics, PaEvents } from 'analytics';

const DashboardPortfolioPage = () => {
  useEffect(() => {
    ProductAnalytics.trackPageView(PaEvents.DASHBOARD_VIEW);
  }, []);

  return <DashboardPortfolio />;
};

export default withPublicLayout(DashboardPortfolioPage);
