/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { SimpleGrid, Tooltip } from '@mantine/core';
import { useSelector } from 'react-redux';
import { hideNotification, showNotification } from '@mantine/notifications';
import { companyListLoadErrorNotification } from 'pages/homepage/HomepageNotifications';
import { useGetCompanyListQuery } from 'services/company/company.service';
import { ProductAnalytics, PaEvents } from 'analytics';

import CompaniesSkeleton from './CompaniesSkeleton';
import CompanyCard from './CompanyCard';

type Props = any;

const Companies: React.FC<Props> = (props) => {
  const token = useSelector((state: any) => state.auth0.token);
  const skip = !token;
  const { data: companies, error, isLoading } = useGetCompanyListQuery(undefined, { skip });

  useEffect(() => {
    if (error) {
      showNotification(companyListLoadErrorNotification);
    }
    return () => {
      hideNotification(companyListLoadErrorNotification.id as string);
    };
  }, [error]);

  if (error || isLoading || !companies) {
    return <CompaniesSkeleton />;
  }

  const handleCardClick = (deal_id: string, deal_name: string, external?: boolean, external_platform?: string) => {
    ProductAnalytics.trackEvent(PaEvents.DEAL_CARD_CLICK, {
      deal_id,
      deal_name,
      external_platform,
      deal_type: external ? 'external' : 'internal',
    });
  };

  const renderCompanyCard = (company: any) => {
    const {
      id,
      banner_url,
      location,
      logo_url,
      company_name,
      company_summary,
      tags,
      end_date,
      start_date,
      active,
      external,
      external_link,
      external_platform,
    } = company;

    const isDealExpired = moment(end_date).isBefore(moment());
    const cardDisabled = isDealExpired || !active;

    const companyCard = (
      <CompanyCard
        bannerUrl={banner_url}
        location={location}
        logoUrl={logo_url}
        name={company_name}
        summary={company_summary}
        tags={tags}
        dealEnd={end_date}
        dealStart={start_date}
        external={external}
        external_platform={external_platform}
      />
    );

    if (cardDisabled) {
      return (
        <div key={id} style={{ cursor: 'not-allowed' }}>
          {companyCard}
        </div>
      );
    }

    const linkProps = external
      ? {
          to: external_link || '#',
          target: '_blank',
        }
      : { to: id };

    return (
      <Tooltip
        key={id}
        label={external ? `This will redirect to our partner ${external_platform} platform.` : ''}
        disabled={!external}
      >
        <Link
          {...linkProps}
          onClick={() => handleCardClick(id, company_name, external, external_platform)}
          style={{ cursor: 'pointer' }}
        >
          {companyCard}
        </Link>
      </Tooltip>
    );
  };

  return (
    <SimpleGrid
      cols={6}
      spacing="xl"
      breakpoints={[
        { maxWidth: 1920, cols: 5, spacing: 'xl' },
        { maxWidth: 1600, cols: 4, spacing: 'xl' },
        { maxWidth: 1200, cols: 3, spacing: 'lg' },
        { maxWidth: 992, cols: 2, spacing: 'md' },
        { maxWidth: 768, cols: 1, spacing: 'sm' },
      ]}
      {...props}
    >
      {companies.items.map(renderCompanyCard)}
    </SimpleGrid>
  );
};

export default Companies;
