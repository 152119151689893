import React from 'react';
import { Accordion, Title, Text } from '@mantine/core';

const faqData = {
  title: 'Tokenfolio Gifting FAQ',
  subtitle: '',
  items: [
    {
      id: 'billing',
      question: 'What is the Tokenfolio Gifting Program?',
      answer:
        'This program is our initiative to reward our existing investors and to provide their family, friends, and network with exclusive private investment opportunities with Tokenfolio.',
    },
    {
      id: 'refund',
      question: 'How does it work?',
      answer:
        'When your network signs up with your code, they get $1000 USD credits which can be redeemed on their first investment with us. When they make their first investment, we’ll credit $1,000 USD to your account which you can use to invest on any TF deal you like.',
    },
    {
      id: 'cancel',
      question: 'How do I use my $1,000 reward?',
      answer:
        'You can use your $1000 credit to invest in any deals you like. Unused credits will expire in 90 days. The credits can’t be applied on discounted fees, that is, it can’t be combined with any other discounts.',
    },
    {
      id: 'trial',
      question: 'Is there a minimum investment required to enjoy the reward benefit?',
      answer:
        'Each deal has its own minimum ticket size, this is typically constrained by the US securities laws as we are limited by the investor numbers. Your rewards can be redeemed in any deals as long as you meet the minimum investment requirements, pass our accreditation and KYC checks.',
    },
    {
      id: 'support',
      question: 'How many friends can I refer?',
      answer: 'There is no limit on the number of people you can refer to.',
    },
    {
      id: 'discounts',
      question: 'Where can I track my gift rewards?',
      answer:
        'You can track your gifting rewards by logging into your Tokenfolio account and checking your gift dashboard. This will provide you with real-time updates on your referrals and rewards.',
    },
    {
      id: 'expiry_date',
      question: 'Is there an expiration date for the gift rewards?',
      answer: 'Yes, your credits expire after 90 days.',
    },
  ],
};

const FAQ = () => (
  <div className="faq-box">
    <Title order={3} align="center" mb="sm">
      {faqData.title}
    </Title>
    <Text color="dimmed" align="center" mb="xl">
      {faqData.subtitle}
    </Text>

    <Accordion variant="separated" radius="md" defaultValue="">
      {faqData.items.map((item) => (
        <Accordion.Item key={item.id} value={item.id}>
          <Accordion.Control>
            <b>{item.question}</b>
          </Accordion.Control>
          <Accordion.Panel>{item.answer}</Accordion.Panel>
        </Accordion.Item>
      ))}
    </Accordion>
  </div>
);

export default FAQ;
