import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { MantineProvider, MantineTheme, Loader } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { useAuth0 } from '@auth0/auth0-react';

import NotFoundPage from 'pages/error/404/NotFoundPage';
import Homepage from 'pages/homepage/Homepage';
import Login from 'pages/login/Login';
import Logout from 'pages/logout/Logout';
import SettingsPageLayout from 'pages/settings/SettingsPageLayout';
import BasicSettingsPage from 'pages/settings/basic/BasicSettingsPage';
import IdentitySettingsPage from 'pages/settings/identity/IdentitySettingsPage';
import theme from './styles/theme';
import {
  PATH_LOGIN,
  PATH_LOGOUT,
  PATH_ROOT,
  PATH_SETTINGS,
  PATH_DASHBOARD,
  PATH_DEALS_PAGE,
  PATH_DEALS_COMPLETION,
  PATH_INVITE_FRIENDS,
} from 'shared/constants';
import './index.scss';
import { setShouldConfirm } from 'pages/settings/SettingsSlice';
import { useAppDispatch } from 'store/hooks';
import DealsPage from 'pages/DealsPage';
import DashboardPortfolioPage from 'pages/DashboardPortfolioPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InvestmentPage from 'pages/InvestmentPage';
import InvitePage from 'pages/InvitePage';
import { ProductAnalytics } from 'analytics';

ProductAnalytics.init(process.env.REACT_APP_PRODUCT_ANALYTICS || '', {
  debug: process.env.REACT_APP_ENV !== 'prd',
  sessionRecording: process.env.REACT_APP_ENV === 'prd',
});

const App = () => {
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently, user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const [showReferralModal, setShowReferralModal] = useState(false);
  const location = useLocation();

  // Set User details
  useEffect(() => {
    if (user && user.email) {
      ProductAnalytics.identifyUser(user.email, {
        id: user.sub || '',
        email: user.email || '',
        name: user.name || '',
      });
    }
  }, [user]);

  useEffect(() => {
    const fetchShouldConfirmUser = async () => {
      const accessToken = await getAccessTokenSilently();
      const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL || '';
      const userId = user?.sub || '';

      if (!userId) return;

      try {
        const res = await fetch(`${baseUrl}v1/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Access-Control-Allow-Headers': '*',
          },
        });

        const data = await res.json();
        const isFirstLogin = data?.logins_count === 1; // Example logic for first login

        if (data?.accredited_investor === true) {
          dispatch(setShouldConfirm(false));
        } else {
          dispatch(setShouldConfirm(true));
        }

        if (isFirstLogin) {
          setShowReferralModal(true); // Show referral modal for new users
        }
      } catch (error) {
        dispatch(setShouldConfirm(true));
      }
    };

    if (user) {
      fetchShouldConfirmUser();
    }
  }, [user, getAccessTokenSilently, dispatch]);

  // Show loading until Auth0 is done
  if (isLoading) {
    return (
      <div className="loader-container">
        <Loader />
        Loading...
      </div>
    );
  }

  // Redirect to login if not authenticated
  if (!isAuthenticated) {
    loginWithRedirect({ appState: { returnTo: location.pathname } });
    return null;
  }

  return (
    <MantineProvider theme={theme as unknown as MantineTheme} withCSSVariables withGlobalStyles withNormalizeCSS>
      <NotificationsProvider>
        <ToastContainer />
        <Routes>
          <Route path={PATH_ROOT} index element={<Homepage />} />
          <Route path={PATH_LOGIN} element={<Login />} />
          <Route path={PATH_LOGOUT} element={<Logout />} />
          <Route path={PATH_DEALS_COMPLETION} element={<InvestmentPage />} />
          <Route path={PATH_DEALS_PAGE} element={<DealsPage />} />
          <Route path={PATH_DASHBOARD} element={<DashboardPortfolioPage />} />
          <Route path={PATH_SETTINGS} element={<SettingsPageLayout />}>
            <Route index element={<Navigate replace to="basic" />} />
            <Route path="basic" element={<BasicSettingsPage />} />
            <Route path="identity" element={<IdentitySettingsPage />} />
            <Route path="entity" element={<BasicSettingsPage />} />
            {/* <Route path="portfolio" element={<PortfolioSettingsPage />} /> */}
          </Route>
          <Route path={PATH_INVITE_FRIENDS} element={<InvitePage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </NotificationsProvider>
    </MantineProvider>
  );
};

export default App;
