import React from 'react';
import { Radio, Stack, Text, Card, Group } from '@mantine/core';
import './RadioCard.scss';

interface IRadioCard {
  value: string;
  title: string;
  description: string;
  statusClickDisabled?: boolean;
  status?: string;
  color?: string;
  isSelected: boolean; // To handle selection state
  onChange: (value: string) => void;
}

const RadioCard = ({
  value,
  title,
  description,
  status,
  onStatusClick,
  statusClickDisabled,
  isSelected,
  color,
  onChange,
}: IRadioCard & { onStatusClick: () => void }) => (
  <Card shadow="sm" radius="sm" withBorder className={`radio-card ${isSelected ? 'selected' : ''}`}>
    <Group noWrap className="radio-card-group">
      <div
        className={`radio-box ${isSelected ? 'selected' : ''}`}
        onClick={(e) => {
          e.stopPropagation(); // Prevent propagation to parent
          onChange(value); // Call the handler to change value
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            onChange(value); // Allow keyboard interaction (Enter or Space)
          }
        }}
        role="radio"
        aria-checked={isSelected}
        tabIndex={0} // Makes the div focusable
      >
        <Radio
          value={value}
          checked={isSelected}
          classNames={{
            body: 'custom-radio-body',
            label: 'custom-radio-label',
            inner: 'custom-radio-inner',
          }}
          onClick={(e) => {
            e.stopPropagation(); // Prevent card click on radio click
          }}
        />
      </div>

      <Stack spacing={0} p="md">
        <Group className="status-box">
          <Text className="title">{title}</Text>
          {status && (
            <Text
              size="xs"
              color="blue"
              style={{
                cursor: statusClickDisabled ? 'not-allowed' : 'pointer',
                color: 'black',
                backgroundColor: color || '#FFE066',
                padding: '3px 8px',
                fontSize: '12px',
                borderRadius: '4px',
              }}
              onClick={() => !statusClickDisabled && onStatusClick()}
            >
              {status}
            </Text>
          )}
        </Group>
        <Text size="sm" className="description">
          {description}
        </Text>
      </Stack>
    </Group>
  </Card>
);

RadioCard.defaultProps = {
  status: undefined,
  color: '#FFE066',
  statusClickDisabled: false,
};

export default RadioCard;
