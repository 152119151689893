import React from 'react';
import { Card, Text, Group, Badge, Stack, ThemeIcon } from '@mantine/core';
import { IconWallet, IconTrendingUp, IconTrendingDown, IconCash, IconPercentage } from '@tabler/icons';

interface PortfolioCardProps {
  holdings: number;
  currentValue: number;
  gainLoss: number;
  totalReturn: number;
  totalInvested: number;
}

const formatNumber = (value: number | null | undefined): string => {
  if (value === null || value === undefined) return 'N/A';
  return Intl.NumberFormat('en-US').format(Number(value));
};

const PortfolioCard: React.FC<PortfolioCardProps> = ({
  holdings,
  currentValue,
  gainLoss,
  totalReturn,
  totalInvested,
}) => {
  const isProfit = gainLoss >= 0;
  const gainLossColor = isProfit ? '#28a745' : '#dc3545'; // Green for profit, red for loss
  const gainLossSign = isProfit ? '+' : '-';

  const data = [
    {
      label: 'Current Value*',
      value: `$${formatNumber(currentValue)}`,
      icon: <IconWallet size={10} />,
    },
    {
      label: 'Invested Value',
      value: `$${formatNumber(totalInvested)}`,
      icon: <IconCash size={10} />,
    },
    {
      label: 'Profit & Loss',
      value: `${gainLossSign}$${formatNumber(Math.abs(gainLoss))}`,
      color: gainLossColor,
      icon: isProfit ? <IconTrendingUp size={10} color="#28a745" /> : <IconTrendingDown size={10} color="#dc3545" />,
    },
    {
      label: 'Return',
      value: `${gainLossSign}${formatNumber(totalReturn)}%`,
      color: gainLossColor,
      icon: <IconPercentage size={10} />,
    },
  ];

  return (
    <Card shadow="sm" p="lg" radius="md" withBorder>
      <Group position="apart">
        <Stack spacing={4}>
          <Text size="xl" weight={700}>
            Portfolio
          </Text>
        </Stack>
      </Group>

      <Group spacing={40} mt="lg">
        {data.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Stack key={index} spacing={4} align="center">
            <Text size="sm" color="dimmed">
              <Group spacing={4}>
                {item.icon && (
                  <ThemeIcon variant="default" size={20} radius="md" style={{ border: 'none' }}>
                    {item.icon}
                  </ThemeIcon>
                )}
                {item.label}
              </Group>
            </Text>
            <Group>
              <Text size="sm" color={item.color}>
                {item.value}
              </Text>
              {/* {item.iconBody && 
                 <ThemeIcon variant="default" size={20} radius="md" style={{ border: 'none'}}>
                   {item.iconBody}
                 </ThemeIcon>
              } */}
              {/* {item.icon} */}
            </Group>
          </Stack>
        ))}
      </Group>
    </Card>
  );
};

export default PortfolioCard;
