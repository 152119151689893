import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQuery } from 'utils/apiUtils';
import { RootState } from 'store/store';
import { IDeals } from 'features/Deals';

export const dealsApi = createApi({
  reducerPath: 'dealsApi',
  baseQuery: async (args, api, extraOptions) => {
    const { token } = (api.getState() as RootState).auth0;
    const baseQuery = createBaseQuery(token);
    return baseQuery(args, api, extraOptions);
  },
  endpoints: (builder) => ({
    getDeals: builder.query<IDeals, string>({
      query: (dealId: string) => `/deals/${dealId}`,
      // query: (dealId: string) => `/deals/space-x-5`,
    }),
  }),
});

export const { useGetDealsQuery } = dealsApi;
