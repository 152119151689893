/* eslint-disable no-nested-ternary */
import React, { FormEvent, useCallback, useEffect, useState } from 'react';

import { Button, Card, Grid, Select, Space, TextInput, Title, Checkbox, Text } from '@mantine/core';
import { useAuth0 } from '@auth0/auth0-react';

import backendConfig from 'config/backend.config';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectShouldConfirm, setShouldConfirm } from 'pages/settings/SettingsSlice';

import './styles.scss';

type FormInput = {
  first_name?: string;
  last_name?: string;
  email?: string;
  middle_names?: string;
  country_of_residence?: string;
  pan_number?: string;
  accredited_investor?: any;
};

const BasicSettingsPage: React.FC = () => {
  const { getAccessTokenSilently, user, isLoading } = useAuth0();
  const [formInput, setFormInput] = useState<FormInput>({});
  const shouldConfirm = useAppSelector(selectShouldConfirm);

  const dispatch = useAppDispatch();
  const [updateMessage, setUpdateMessage] = useState<string>('');
  const [userData, setUserData] = useState<any>();
  const [countries, setCountries] = useState<any>([]);
  const [searchValue, onSearchChange] = useState('');
  const [selectedCountry, setSelectedCountry] = useState<string | null>('');

  useEffect(() => {
    setFormInput((val) => ({
      ...val,
      email: user?.email,
    }));
  }, [user]);

  const fetchCountries = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const usersUrl = new URL(`v1/countries`, backendConfig.baseUrl);
      const res = await fetch(usersUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': '*',
        },
      });
      const data = await res.json();
      const countriesData = Object.keys(data)?.map((code) => ({ label: data[code], value: code }));

      // fetch countries
      setCountries(countriesData);
    } catch (err) {
      console.log(err);
    }
  };

  const updateUserData = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    if (!user?.sub) throw Error('Unknown user ID!');
    const infoUpdateUrl = new URL(`v1/users/${user?.sub}`, backendConfig.baseUrl);
    return fetch(infoUpdateUrl, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
      },
      body: JSON.stringify(formInput),
    });
  }, [formInput, getAccessTokenSilently, user?.sub]);

  const getUserDetails = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      if (!user?.sub) throw Error('Unknown user ID!');
      const usersUrl = new URL(`v1/users/${user?.sub}`, backendConfig.baseUrl);
      const res = await fetch(usersUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          // 'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': '*',
        },
      });
      const data = await res.json();
      setUserData(data);
      setSelectedCountry(data?.country_of_residence || '');
      setFormInput((val) => ({
        ...formInput,
        ...val,
        ...data,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getUserDetails();
    fetchCountries();
  }, []);

  useEffect(() => {
    setFormInput((val) => ({
      ...val,
      country_of_residence: selectedCountry === null ? '' : selectedCountry,
    }));
  }, [selectedCountry]);

  const onSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      try {
        const response = await updateUserData();
        if (!response.ok) {
          setUpdateMessage('Failed to save information! Please try again.');
          setTimeout(() => setUpdateMessage(''), 4000);
          return;
        }

        // Parse the JSON response
        const data = await response.json();
        // Check the `accredited_investor` field in the response
        const isAccredited = data.accredited_investor === true || false;

        // Dispatch updates and show a success message
        dispatch(setShouldConfirm(!isAccredited));
        setUpdateMessage('Information saved successfully.');
        // Clear the message after 4 seconds
        setTimeout(() => setUpdateMessage(''), 4000);
      } catch (error) {
        // Handle any errors that occur during the fetch
        setUpdateMessage('Failed to save information! Please try again.');
        setTimeout(() => setUpdateMessage(''), 4000);
      }
    },
    [updateUserData]
  );

  const handleFormInputChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const { name, value, checked } = e.currentTarget;
      if (name === 'accredited_investor') {
        setFormInput({
          ...formInput,
          [name]: checked,
        });
      } else {
        setFormInput({
          ...formInput,
          [name]: value,
        });
      }
    },
    [formInput]
  );

  const checkIfValuesProvided = () =>
    Boolean(
      formInput?.country_of_residence &&
        (formInput?.country_of_residence === 'IND' ? formInput?.pan_number : true) &&
        formInput?.first_name &&
        formInput?.last_name &&
        formInput?.email
    );

  console.log('user && userData', user, userData);

  const settingsAlertContent =
    (user && userData && !userData?.first_name) || !userData?.last_name
      ? 'Please update your information and press confirm'
      : user && userData && shouldConfirm
      ? 'You need to confirm your details to verify your account'
      : null;

  return (
    <>
      <Title order={1} my="lg">
        Basic Info
      </Title>
      {!user?.email_verified && (
        <Card withBorder p="xl" my="lg">
          <Text style={{ textAlign: 'center' }}> Please check your email and verify your account.</Text>
        </Card>
      )}
      {/* { settingsAlertContent && (
        <Card className="alert-banner-card">
          <div className="alert-banner">
            <IconInfoCircle color="#FF3333" />
            <Title order={5} color="#FF3333">
              {settingsAlertContent}
            </Title>
          </div>
        </Card>
      )} */}
      {user?.email_verified && (
        <Card withBorder p="xl" my="lg">
          <Grid>
            <Grid.Col span={5}>
              <Title order={4}>Profile settings</Title>
            </Grid.Col>
            <Grid.Col span={7}>
              <form onSubmit={onSubmit}>
                <TextInput
                  label="First name"
                  placeholder="First name"
                  name="first_name"
                  size="md"
                  disabled={isLoading}
                  defaultValue={formInput?.first_name ?? ''}
                  onChange={handleFormInputChange}
                />
                <Space h="md" />
                <TextInput
                  label="Middle name"
                  placeholder="Middle name"
                  name="middle_names"
                  size="md"
                  disabled={isLoading}
                  defaultValue={formInput?.middle_names ?? ''}
                  onChange={handleFormInputChange}
                />
                <Space h="md" />
                <TextInput
                  label="Last name"
                  placeholder="Last name"
                  name="last_name"
                  size="md"
                  disabled={isLoading}
                  defaultValue={formInput?.last_name ?? ''}
                  onChange={handleFormInputChange}
                />
                <Space h="md" />
                <TextInput
                  label="Email address"
                  placeholder="Email address"
                  name="email"
                  size="md"
                  disabled
                  value={user?.email ?? ''}
                />

                <Space h="md" />

                <Select
                  label="Country of residence"
                  placeholder="Select Country"
                  searchable
                  onSearchChange={onSearchChange}
                  searchValue={searchValue}
                  nothingFound="No options"
                  onChange={setSelectedCountry}
                  value={selectedCountry}
                  data={countries}
                  className="country-select"
                />
                {selectedCountry === 'IND' && (
                  <>
                    <Space h="md" />
                    <TextInput
                      label="PAN Number"
                      placeholder="Enter PAN Number"
                      name="pan_number"
                      size="md"
                      defaultValue={formInput?.pan_number ?? ''}
                      onChange={handleFormInputChange}
                    />
                  </>
                )}
                <Space h="md" />

                <Checkbox
                  radius="xs"
                  checked={formInput?.accredited_investor}
                  name="accredited_investor"
                  defaultValue={formInput?.accredited_investor}
                  onChange={handleFormInputChange}
                  label={
                    <Text fz="md">
                      I verify that I&apos;m an{' '}
                      <a
                        className="highlighted-anchor"
                        href="https://help.angellist.com/hc/en-us/articles/360048803251-Accredited-Investors"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {' '}
                        accredited{' '}
                      </a>{' '}
                      investor
                    </Text>
                  }
                />
                <Button disabled={!checkIfValuesProvided()} type="submit" color="primary" mt="xl" mb="xs" size="md">
                  {shouldConfirm ? 'Confirm' : 'Update'}
                </Button>
                {updateMessage && (
                  <span
                    style={{
                      display: 'inline-block',
                      marginLeft: 10,
                      verticalAlign: 'middle',
                      color: updateMessage.startsWith('Failed') ? 'red' : 'black',
                    }}
                  >
                    {updateMessage}
                  </span>
                )}
              </form>
            </Grid.Col>
          </Grid>
        </Card>
      )}
    </>
  );
};

export default BasicSettingsPage;
