import React from 'react';
import { Card, Text, Group, Divider, Checkbox, HoverCard, Flex } from '@mantine/core';
import { Link } from 'react-router-dom';
import { IconInfoCircle } from '@tabler/icons';
import { PATH_INVITE_FRIENDS } from 'shared/constants';
import CompanyHeader from 'components/Common/CompanyHeader';
import CardTableView from 'components/Common/CardTableView';

import { getCurrencySymbol, formatNumberWithCommas, formatNumber } from 'utils/currencySymbols';

const InvestmentInfo: React.FC<{ data: any; committedAmount: number; showWalletBalance: boolean }> = ({
  data,
  committedAmount,
  showWalletBalance,
}) => {
  const currency = getCurrencySymbol(data.currency || 'USD');
  const valuation = `${currency} ${formatNumberWithCommas(data.offering_valuation)} valuation`;

  const cardDetails = {
    label: 'Committed amount',
    value: `${currency} ${formatNumber(String(committedAmount))}`,
  };

  const walletBalance = 1000;
  const walletBalanceInStr = `${currency}${formatNumber(String(walletBalance))}`;

  const tooltipMessage =
    walletBalance <= 0 ? (
      <span>
        Get $1000 by referring a friend.{' '}
        <Link to={PATH_INVITE_FRIENDS} style={{ textDecoration: 'underline', color: '#007BFF' }}>
          Click here to Refer
        </Link>
        .
      </span>
    ) : (
      'First-time investors: Credits will be used for investments over $10,000 and against management fees.'
    );

  return (
    <Card shadow="md" p="lg" radius="md">
      <CompanyHeader logoUrl={data.logo_url} name={data.company_name} summary={valuation} size="sm" />
      <Group spacing={10} mt="lg" style={{ justifyContent: 'space-between' }}>
        <Text size="sm"> {data.management_fees}% Mgmt fees </Text>
        <Divider orientation="vertical" />
        <Text size="sm"> {data.carried_interest}% Carry </Text>
        <Divider orientation="vertical" />
        <Text size="sm"> {data.setup_fees || 0.5}% Setup fees </Text>
      </Group>
      <Group mt="sm" align="space-between" className="details-spacing-contatiner">
        <Divider my="s" mb={50} />
        <CardTableView field={cardDetails} />

        {showWalletBalance && (
          <>
            <Divider my="s" mb={50} />
            <Flex justify="space-between" align="center">
              <Checkbox
                label={
                  <Group spacing={5}>
                    <Text>Use TF wallet credits</Text>
                    <Text weight="bold" color="teal">
                      {walletBalanceInStr}
                    </Text>
                  </Group>
                }
                style={{ cursor: 'not-allowed ' }}
                checked
                readOnly
              />
              <HoverCard width={200} shadow="md">
                <HoverCard.Target>
                  <Flex>
                    <IconInfoCircle size={18} style={{ cursor: 'pointer' }} color="var(--mantine-color-primary-6)" />
                  </Flex>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <Text size="sm">{tooltipMessage}</Text>
                </HoverCard.Dropdown>
              </HoverCard>
            </Flex>
          </>
        )}
      </Group>
    </Card>
  );
};

export default InvestmentInfo;
