import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import backendConfig from 'config/backend.config';
import { InvestApi, HandleSubmissionPayload, SignUrlResponse } from 'models/invest/invest.api.model';

export const investApi = createApi({
  reducerPath: 'investment',
  baseQuery: fetchBaseQuery({ baseUrl: backendConfig.baseUrl }),
  endpoints: (builder) => ({
    requestSignUrl: builder.mutation<SignUrlResponse, InvestApi>({
      query: (payload) => ({
        url: `/v1/invest/confirm`,
        method: 'POST',
        body: { ...payload.investmentPayload, deal_id: payload.dealId, entity_id: payload.entity_id },
        // body: payload.investmentPayload,
        headers: {
          Authorization: `Bearer ${payload.accessToken}`,
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
    }),
    handleSubmission: builder.mutation<void, HandleSubmissionPayload>({
      query: (payload) => ({
        url: `/v1/sign/handle-submission?signature_id=${payload.signature_id}&action_type=${payload.action}`,
        method: 'POST',
        body: payload,
        headers: {
          Authorization: `Bearer ${payload.accessToken}`,
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
    }),
  }),
});

export const { useRequestSignUrlMutation, useHandleSubmissionMutation } = investApi;
